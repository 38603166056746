import { httpClient, aiClient } from '@/api';
import { handleError, handleSuccess } from '@/services/helpers';
import { GenericMessage } from '@/types/API';
import { DiscountServicePricesResource } from '@/types/resources/DiscountServicePrices';
import { ServiceSelectionCategoryResource } from '@/types/resources/ServiceSelectionCategories';
import { GPTEndpoints, GPTStage } from '@/types/service_advisor/GPTResponse';
//Types
import { ServicesResource } from '@/types/Services';
import { isNullish } from '@/utilities';

export default {
  fetchServices() {
    return httpClient
      .get<ServicesResource>('/v3/services')
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },
  fetchServiceSelectionCategory(name: string) {
    return httpClient
      .get<ServiceSelectionCategoryResource>('/v3/services/service_selection_categories', {
        params: { name: name }
      })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  /**
   * Retrieve available Lyft packages for a given owned vehicle.
   *
   * @param vehicleId - The ID of the owned vehicle to retrieve eligibile Lyft packages for.
   * @returns If successful, a promise containing the Lyft packages for the vehicle.
   *          It can also be successful and return an object with a message whether the
   *          Vehicle ID supplied is package eligible, or the zipcode is package eligible.
   *
   */
  fetchLyftPackages(ownedVehicleId: number, zipcode: string) {
    return httpClient
      .get<DiscountServicePricesResource | GenericMessage>(`/v3/owned_vehicles/${ownedVehicleId}/packages`, {
        params: { zipcode: zipcode }
      })
      .then((response) => {
        //@ts-ignore
        if (!isNullish(response.data.message)) {
          //@ts-ignore
          return response.data.message;
        }
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },
  fetchViaChatGPT(searchTerm: string, sessionId?: string, stage: GPTStage = GPTStage.SERVICES) {
    const optionals = sessionId ? { session_id: sessionId } : {};
    return aiClient.post(GPTEndpoints[stage], { question: searchTerm, ...optionals }).then((r: any) => {
      return r.data;
    });
  },
  vehicleViaChatGpt(searchTerm: string, sessionId?: string) {
    const optionals = sessionId ? { session_id: sessionId } : {};
    return aiClient.post('/vehicle', { question: searchTerm, ...optionals }).then((r: any) => {
      return r.data;
    });
  }
};
