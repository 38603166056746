import { User } from '@/types/User';
import { Program } from '@/types/microservices/Program';
import { Subscription } from '@/types/microservices/Subscriptions';
import { OwnedVehicle } from '@/types/resources/OwnedVehicles';
import Vue from 'vue';

const userMixin = Vue.extend({
  computed: {
    isLoggedIn(): boolean {
      return this.$store.getters['user/isLoggedIn'];
    },

    subscriptions(): Subscription[] {
      return this.$store.getters['user/getSubscriptions'];
    },

    hasSubscription(): boolean {
      return this.$store.getters['user/hasActiveSubscription'];
    },

    programs(): Program[] {
      return this.$store.getters['user/getPrograms'];
    },

    ownedVehicles(): OwnedVehicle[] {
      return this.$store.getters['user/getVehicles'];
    },

    userProfile(): User {
      return this.$store.getters['user/getUserProfile'];
    },

    userPhoneNumber(): string {
      const profile = this.userProfile;

      if (profile) {
        return profile.phoneNumber;
      } else {
        return '';
      }
    }
  }
});

export { userMixin };
