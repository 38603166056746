export default [
  {
    path: '/repair-cost',
    component: () => import(/* webpackChunkName: "repair-cost" */ '@/views/RepairCost.vue'),
    meta: {
      public: true
    },
    children: [
      {
        name: 'repair-cost-makes',
        path: '',
        component: () => import(/* webpackChunkName: "repair-cost" */ '@/components/RepairCost/MakeSelector.vue'),
        meta: {
          whiteBackground: true,
          fluidContainer: true,
          hideMainSlot: true,
          disableTracking: true
        }
      },
      {
        name: 'repair-cost-models',
        path: ':makeName',
        component: () => import(/* webpackChunkName: "repair-cost" */ '@/components/RepairCost/ModelSelector.vue'),
        meta: {
          whiteBackground: true,
          fluidContainer: true,
          hideMainSlot: true,
          disableTracking: true
        }
      },
      {
        name: 'repair-cost-service',
        path: ':makeName/:modelName',
        component: () =>
          import(/* webpackChunkName: "repair-cost" */ '@/components/RepairCost/RepairServiceSelector.vue'),
        meta: {
          whiteBackground: true,
          fluidContainer: true,
          hideMainSlot: true,
          disableTracking: true,
          preventScrollJump: true
        }
      },
      {
        name: 'repair-cost-recent-repairs',
        path: ':makeName/:modelName/recent-repairs',
        component: () =>
          import(/* webpackChunkName: "repair-cost" */ '@/components/RepairCost/RepairServiceSelector.vue'),
        meta: {
          whiteBackground: true,
          fluidContainer: true,
          hideMainSlot: true,
          disableTracking: true,
          preventScrollJump: true
        }
      },
      {
        name: 'repair-cost-service-detail',
        path: ':makeName/:modelName/:serviceSlug',
        component: () =>
          import(/* webpackChunkName: "repair-cost" */ '@/components/RepairCost/RepairServiceSelector.vue'),
        meta: {
          whiteBackground: true,
          fluidContainer: true,
          hideMainSlot: true,
          disableTracking: true
        }
      }
    ]
  }
];
