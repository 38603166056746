export interface SegmentTraits {
  description?: string;
  id: string;
  createdAt: string;
}

export type SegmentServiceRequestProps = {
  metro_id: number;
  service_request_id: string; // Openbay UID
  service_request_service: string[];
  service_request_offer_count: number;
  service_request_service_count: number; // The length of serviceRequestService above.
  service_request_total_price: string;
  vehicle_year: number;
  vehicle_make: string;
  vehicle_model: string;
  vehicle_mileage: number;
};

export type SegmentOfferProps = {
  offer_id: string; //Openbay UID
  sp_company_id: number;
  sp_company_name: string;
  sp_location_id: number;
  sp_location_name: string;
  sp_location_city: string;
  sp_location_state: string;
  sp_location_zipcode: string;
};

export function createSegmentTraits(config: SegmentTraits): {
  id: string;
  description?: string;
  createdAt: string;
} {
  const newTraits = {
    id: config.id ? config.id : '',
    description: config.description ? config.description : '',
    createdAt: config.createdAt ? config.createdAt : ''
  };

  return newTraits;
}
