export default [
  {
    path: '/auto-repair',
    component: () => import(/* webpackChunkName: "auto-repair" */ '@/views/AutoRepair.vue'),
    meta: {
      public: true
    },
    children: [
      {
        name: 'auto-repair-index',
        path: '',
        component: () => import(/* webpackChunkName: "auto-repair" */ '@/components/AutoRepair/StateSelector.vue'),
        meta: {
          whiteBackground: true,
          fluidContainer: true,
          hideMainSlot: true,
          disableTracking: true
        }
      },
      {
        name: 'auto-repair-cities',
        path: ':stateAbbreviation',
        component: () => import(/* webpackChunkName: "auto-repair" */ '@/components/AutoRepair/CitySelector.vue'),
        meta: {
          whiteBackground: true,
          fluidContainer: true,
          hideMainSlot: true,
          disableTracking: true
        }
      },
      {
        name: 'auto-repair-service-locations',
        path: ':stateAbbreviation/:city',
        component: () =>
          import(/* webpackChunkName: "auto-repair" */ '@/components/AutoRepair/ServiceStationLocationSelector.vue'),
        meta: {
          whiteBackground: true,
          fluidContainer: true,
          hideMainSlot: true,
          disableTracking: true,
          appointment: true
        }
      }
    ]
  }
];
