import { NavigationGuard, Route, RouteConfig, RouteMeta } from 'vue-router';

import PartnerService from '@/services/microservices/partnerService';
import store from '@/store/store';
import { Partner } from '@/types/microservices/Partner';
import { Program } from '@/types/microservices/Program';

const validatedProgramPlans: Record<string, Record<string, boolean>> = {};

const setValidatedProgramPlan = (programName: string, planName: string, isValid: boolean): void => {
  validatedProgramPlans[programName] ??= {};
  validatedProgramPlans[programName][planName] = isValid;
};

const getValidatedProgramPlan = (programName: string, planName: string): boolean | undefined => {
  return validatedProgramPlans[programName]?.[planName];
};

const handleCustomDomain = (toRoute: Route, partner: Partner) => {
  if (partner.customDomain && !window.location.host.includes(partner.customDomain)) {
    window.location.replace(`https://${partner.customDomain}${toRoute.fullPath}`);
  }
};

const userIsAssociatedAlready = (programName: string) => {
  const programs: Program[] = store.getters['user/getPrograms'] || [];
  return programs.find((program) => program.name === programName);
};

// Checks to see if the program and plan are valid. If they both are, allows the
// navigation to continue. If one or the other is not, redirects to the home
// page. If we've already checked whether or not they're valid, it will cache
// the result to avoid a network call the next time that specific program and
// plan are checked.
const programAndPlanMustBeValid: NavigationGuard = (to, _from, next): void => {
  const programName = to.params.programName.toLowerCase();
  const planName = to.params.planName.toLowerCase();

  const finishNavigation = (canContinue: boolean): void => {
    if (canContinue) {
      next();
    } else {
      next({ path: '/' });
    }
  };

  const wasValid = getValidatedProgramPlan(programName, planName);
  if (typeof wasValid !== 'undefined') return finishNavigation(wasValid);

  new PartnerService()
    .getPartnerForProgramAndPlan(programName, planName)
    .then((response: any) => {
      if (response.status === 404) return finishNavigation(false);
      handleCustomDomain(to, response);
      setValidatedProgramPlan(programName, planName, true);
      finishNavigation(true); // endpoint 404s if program/plan aren't valid.
    })
    .catch((error) => {
      console.error(error);
      finishNavigation(false);
    });
};

const accountIsConfirmed = (): boolean => {
  return store.getters['user/isConfirmed'];
};

const hasSubscription = (): boolean => (store.getters['user/getSubscriptions'] || []).length > 0;

const defaultSubscriptionMeta = (): RouteMeta => ({
  fluidContainer: true,
  hideMainSlot: true,
  disableTracking: false,
  hideNavLinks: true,
  public: true
});

export const subscriptionCreatePasswordRouteName = 'subscription-create-password';

// NOTE: The selection of a partner logo for the navbar Openbay+ co-branded logo
//       relies on detection of the string `"subscription"` in the route name.
//       If you want the co-branded logo to show, then, well, you better have
//       `"subscription"` somewhere in the route's `name` option. See
//       `src/components/Logo.vue` for current logic.
//
const subscriptionRoutes: RouteConfig[] = [
  {
    path: '/plus/:programName/:planName',
    name: 'subscription',
    component: () => import(/* webpackChunkName: "subscription" */ '@/views/Subscription.vue'),
    beforeEnter: (to, from, next) => {
      const { programName, planName } = to.params;
      programAndPlanMustBeValid(to, from, next);
      if (userIsAssociatedAlready(programName)) {
        if (!accountIsConfirmed())
          return next({ name: 'subscription-activate-account', params: { programName, planName } });
        return next('/dashboard');
      }
    },
    meta: { ...defaultSubscriptionMeta(), landingPage: true }
  },
  {
    path: '/plus/:programName/:planName/create-password',
    name: subscriptionCreatePasswordRouteName,
    component: () => import(/* webpackChunkName: "subscription" */ '@/views/SubscriptionCreatePassword.vue'),
    beforeEnter: programAndPlanMustBeValid,
    meta: defaultSubscriptionMeta()
  },
  {
    path: '/plus/:programName/:planName/success',
    name: 'subscription-success',
    component: () => import(/* webpackChunkName: "subscription" */ '@/views/SubscriptionSuccess.vue'),
    beforeEnter: programAndPlanMustBeValid,
    meta: { ...defaultSubscriptionMeta(), hideNavLinks: false }
  },
  {
    path: '/plus/:programName/:planName/create-password-success',
    name: 'subscription-create-password-success',
    component: () => import(/* webpackChunkName: "subscription" */ '@/views/SubscriptionCreatePasswordSuccess.vue'),
    beforeEnter: programAndPlanMustBeValid,
    meta: defaultSubscriptionMeta()
  },
  {
    path: '/plus/:programName/:planName/activate-account',
    name: 'subscription-activate-account',
    component: () => import(/* webpackChunkName: "subscription" */ '@/views/SubscriptionAccountActivation.vue'),
    beforeEnter: (to, from, next) => {
      if (accountIsConfirmed()) return next('/dashboard');
      programAndPlanMustBeValid(to, from, next);
    },
    meta: { ...defaultSubscriptionMeta(), public: false, hideNavLinks: false, preventScrollJump: true }
  },
  {
    path: '/plus/:programName/:planName/choose-plan',
    name: 'subscription-choose-plan',
    component: () => import(/* webpackChunkName: "subscription" */ '@/views/SubscriptionSuccess.vue'),
    beforeEnter: (to, from, next) => {
      if (hasSubscription()) next('/dashboard');
      programAndPlanMustBeValid(to, from, next);
    },
    meta: { ...defaultSubscriptionMeta(), public: false, hideNavLinks: false }
  }
];

export default subscriptionRoutes;
