




import Vue from 'vue';
import { BFormSelect } from 'bootstrap-vue';

export default Vue.extend({
  name: 'MonthSelect',

  components: {
    'b-form-select': BFormSelect
  },

  props: {
    initialMonth: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      selected: this.initialMonth,
      options: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    };
  },

  methods: {
    monthChanged(month: string) {
      this.$emit('month-changed', month);
    }
  }
});
