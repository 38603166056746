


















































import localforage from 'localforage';
import { Route } from 'vue-router';

import AppointmentNavigation from '@/components/Appointment/AppointmentNavigation.vue';
import GlobalAlert from '@/components/GlobalAlert.vue';
import GlobalSearch from '@/components/GlobalSearch/GlobalSearch.vue';
import JoinPlusBanner from '@/components/JoinPlusBanner.vue';
//Components
import Navbar from '@/components/Navbar.vue';
import OnrampNavigation from '@/components/Onramp/OnrampNavigation.vue';
import JoinPlusModal from '@/components/Subscription/JoinPlus/JoinPlusModal.vue';
import SubscriptionOptionsModal from '@/components/Subscription/SubscriptionOptionsModal.vue';
import { JoinPlusBannerConfig, PartnerBannerConfigs } from '@/constants/plus-banner';
import GlobalFooter from '@/components/GlobalFooter.vue';

import messageService from './services/messageService';

import CombinedComponent, { userMixin } from './mixins';
import { fetchConfig } from './init/app-config';

export default CombinedComponent(userMixin).extend({
  name: 'OpenbayUserInterface',
  metaInfo(): any {
    return {
      title: 'Find, compare, book, automotive repair & maintenance services | Openbay',
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content:
            'Find local trustworthy auto repair professionals. Compare multiple competitive price estimates and book service in a few simple steps.'
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: 'Find, compare, book, automotive repair & maintenance services | Openbay'
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content:
            'Find local trustworthy auto repair professionals. Compare multiple competitive price estimates and book service in a few simple steps.'
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: `${window.location.origin}${this.$route.path}`
        }
      ]
    };
  },

  components: {
    Navbar,
    GlobalSearch,
    OnrampNavigation,
    GlobalAlert,
    JoinPlusBanner,
    SubscriptionOptionsModal,
    JoinPlusModal,
    AppointmentNavigation,
    GlobalFooter
  },

  data() {
    return {
      loading: true,
      prevHeight: '0',
      transitionName: 'slide',
      pollingInterval: 0,
      transitionException: ['sign-up', 'sign-in', 'home'] // Array of route names that should not be animated on enter/exit
    };
  },
  computed: {
    showGlobalSearch(): boolean {
      return (
        this.isLoggedIn && !this.showCloseButtonInNav && this.$route.name !== 'invite-page' && this.hasServiceRequests
      );
    },
    showCloseButtonInNav(): boolean {
      return this.$route.meta?.showCloseButtonInNav === true ? true : false;
    },
    showAppointmentNav(): boolean {
      return this.$route.meta?.appointmentNav;
    },
    hasAlerts(): boolean {
      return this.$store.getters['alerts/getAlerts'].length > 0;
    },
    fluidContainer(): boolean {
      return this.$route.meta?.fluidContainer;
    },
    hasServiceRequests(): boolean {
      return this.$store.getters['user/getHasServiceRequests'];
    },
    hasWhiteBackground(): boolean {
      //TODO: this should be handled in individual view components, along with the container
      return this.showCloseButtonInNav || this.$route.meta?.inProfile || this.$route.meta?.whiteBackground;
    },
    cacheExclude(): string[] {
      return ['MessagesPage', 'Onramp', 'Dashboard', 'Maintenance', 'RescheduleSelector'];
    },
    cacheInclude(): string[] {
      return [''];
    },
    userName(): string {
      return this.$store.getters['user/getDisplayName'];
    },
    isLyftUser(): boolean {
      return this.$store.getters['user/isLyftUser'];
    },
    subscriptionsLoaded(): boolean {
      return this.$store.getters['user/getSubscriptionsLoaded'];
    },
    bannerConfig(): JoinPlusBannerConfig {
      if (this.isLyftUser) return PartnerBannerConfigs['lyft'];

      return PartnerBannerConfigs['openbayPlus'];
    },
    accountIsConfirmed(): boolean {
      return this.$store.getters['user/isConfirmed'];
    },
    joinPlusModal(): InstanceType<typeof JoinPlusModal> {
      return this.$refs['joinPlusModal'] as InstanceType<typeof JoinPlusModal>;
    },
    isSubscriptionRoute(): boolean {
      if (this.$route.name === 'sign-up-success') return true;

      return !!(this.$route.name?.match(/subscription/) && this.$route.params?.programName);
    },
    showJoinPlusBanner(): boolean {
      return (
        !this.$route.path.includes('offer') &&
        this.subscriptionsLoaded &&
        !this.hasSubscription &&
        !this.isSubscriptionRoute &&
        this.accountIsConfirmed
      );
    },
    subscriptionModalRef(): InstanceType<typeof SubscriptionOptionsModal> {
      return this.$refs['modal'] as InstanceType<typeof SubscriptionOptionsModal>;
    },
    zipcode(): string {
      return this.$store.getters['onrampCart/getZipcode'];
    },
    openbayId(): string {
      const { openbayId } = this.$store.getters['user/getUserProfile'];
      return openbayId;
    }
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      handler: function (to) {
        switch (to) {
          case true:
            this.pollingInterval = window.setInterval(() => {
              this.fetchPollingData();
            }, 600000);
            break;
          case false:
            clearInterval(this.pollingInterval);
            break;
          default:
            return;
        }
      }
    },
    openbayId: {
      immediate: true,
      handler(to: string) {
        this.rollbarIdentify(to);
      }
    }
  },
  created(): void {
    this.setupAppData();
    this.initializeRouterOptions();
    this.$root.$on('joinplus::modal::show', (fromPage: string) => {
      this.showSubscriptionOptionsModal(fromPage);
    });
  },
  methods: {
    beforeLeave(element: HTMLElement): void {
      this.prevHeight = `${getComputedStyle(element).height}`;
    },
    enter(_element: HTMLElement): void {},
    afterEnter(element: HTMLElement): void {
      element.style.height = '100%';
    },
    setupAppData(): void {
      this.fetchServices();

      if (this.isLoggedIn === true) {
        this.handleWelcomeMessage();
        this.$store.dispatch('user/fetchVehicles').finally(() => (this.loading = false));
      } else {
        this.loading = false;
      }
    },
    handleWelcomeMessage(): void {
      let welcomeMessage = '';
      if (this.userProfile.unreadCount > 0) {
        // Always show the welcome message if the user has unread messages
        welcomeMessage = `Welcome back ${this.userName}. You have ${this.userProfile.unreadCount} new messages.`;
        this.$store.dispatch('alerts/addAlert', {
          message: welcomeMessage,
          type: 'info',
          timedDismiss: true
        });
      } else {
        localforage.getItem('seenWelcome').then((seen) => {
          if (seen) return;

          welcomeMessage = `Welcome back ${this.userName}.`;
          this.$store.dispatch('alerts/addAlert', {
            message: welcomeMessage,
            type: 'info',
            timedDismiss: true
          });
        });
      }

      localforage.setItem('seenWelcome', true);
    },
    initializeRouterOptions(): void {
      this.$router.beforeEach((to: Route, from: Route, next: any) => {
        const toRouteName = to.name || '';
        const fromRouteName = from.name || '';
        if (this.transitionException.includes(toRouteName) || this.transitionException.includes(fromRouteName)) {
          this.transitionName = '';
          return next();
        }
        let transitionName = to.meta?.transitionName || from.meta?.transitionName || 'slide-left';

        if (transitionName === 'slide') {
          const toDepth = to.path.split('/').length;
          const fromDepth = from.path.split('/').length;
          transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
        }

        this.transitionName = transitionName;

        next();
      });
    },
    fetchPollingData(): void {
      messageService.fetchMessageThreads();
    },
    fetchServices(): void {
      this.$store.dispatch('services/fetchPopularServices');
      this.$store.dispatch('services/fetchDescribeProblem');
      this.$store.dispatch('services/fetchServiceCatalog');
    },
    showSubscriptionOptionsModal(fromPage: string) {
      this.subscriptionModalRef.setFromPage(fromPage);
      this.subscriptionModalRef.showModal();
    },
    onSubscribed(): void {},
    rollbarIdentify(openbayId: string) {
      if (fetchConfig().get('VUE_APP_ENVIRONMENT') === 'local') return;
      this.$rollbar.configure({
        payload: {
          person: {
            id: openbayId
          }
        }
      });
    }
  }
});
