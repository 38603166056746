import { httpClientMicro } from '@/api-microservices';
import { Config, fetchConfig } from '@/init/app-config';
import { ApiListResponse, Pagination, Sort } from '@/types/microservices/api';
import { CreateSubscriptionRequest } from '@/types/microservices/CreateSubscriptionRequest';
import { DetailedSubscription } from '@/types/microservices/DetailedSubscription';
import { PaymentMethod } from '@/types/microservices/PaymentMethod';
import { ProcessorPayment } from '@/types/microservices/ProcessorPayment';
import { ProgramPlanFaq } from '@/types/microservices/ProgramPlanFaq';
import { SubscriptionOption } from '@/types/microservices/SubscriptionOption';
import { Subscription } from '@/types/microservices/Subscriptions';
import { UserSubscriptionResponse } from '@/types/microservices/UserSubscriptionResponse';
import { PartialVehicle } from '@/types/resources/PartialVehicle';
import { formatSortParameter } from '@/utilities';

import { handleError } from '../helpers';

export default class SubscriptionService {
  config: Config;
  routes = {
    mySubscriptions: () => `${this.environmentPath()}/v1/my-subscriptions`,
    partialVehicles: () => `${this.environmentPath()}/v1/partial-vehicles`,
    partialVehicleAssociate: (partialVehicleId: number) =>
      `${this.environmentPath()}/v1/partial-vehicles/${partialVehicleId}`,
    mySubscriptionOptions: (programPlanId?: number) =>
      `${this.routes.mySubscriptions()}/subscription-options/${programPlanId ?? ''}`,
    subscribeWithOption: () => `${this.routes.mySubscriptions()}/subscribe`,
    cancelSubscription: (subscriptionId: number) => `${this.routes.mySubscriptions()}/${subscriptionId}/cancel`,
    subscriptionDetails: (programPlanId: number) =>
      `${this.routes.mySubscriptions()}/detailed?programPlanId=${programPlanId}`,
    paymentHistory: (processorSubscriptionId: string) =>
      `${this.routes.mySubscriptions()}/${processorSubscriptionId}/payments`,
    paymentIntent: () => `${this.routes.mySubscriptions()}/stripe/payment-intent`,
    paymentMethods: () => `${this.routes.mySubscriptions()}/payment-methods`,
    setupIntent: () => `${this.routes.mySubscriptions()}/stripe/setup-intent`,
    updatePaymentMethod: (processorSubscriptionId: string) =>
      `${this.routes.mySubscriptions()}/${processorSubscriptionId}`,
    subscriptionFaqs: () => `${this.environmentPath()}/v1/my-subscriptions/faq`,
    check: () => `${this.environmentPath()}/v1/my-subscriptions/check`
  };
  constructor() {
    this.config = fetchConfig();
  }
  environmentPath() {
    return this.config.get('VUE_APP_SUBSCRIPTION_API_BASE_URL');
  }

  public subscribeVOToPlan(
    programNameSlug: string,
    planNameSlug: string,
    zipCode?: string,
    sourceId?: string,
    partnerId?: number
  ): Promise<{ message: string }> {
    return httpClientMicro.post(
      this.routes.mySubscriptions(),
      {
        programName: programNameSlug,
        programPlanName: planNameSlug,
        ...(zipCode ? { zipCode } : {}),
        ...(sourceId ? { sourceId } : {})
      },
      { headers: { 'x-partner-id': partnerId } }
    );
  }

  public getVOSubscriptions(): Promise<Subscription[]> {
    return httpClientMicro.get(this.routes.mySubscriptions()).then(({ data }) => data.data);
  }

  public getSubscriptionHistory(partnerId?: number): Promise<Subscription[]> {
    return httpClientMicro
      .get(this.routes.mySubscriptions(), {
        params: {
          isActive: false
        },
        headers: { 'x-partner-id': partnerId }
      })
      .then(({ data }) => data.data);
  }

  public getPartialVehicles(): Promise<PartialVehicle[]> {
    return httpClientMicro.get(this.routes.partialVehicles()).then(({ data }) => data);
  }

  public getSubscriptionOptions(programPlanId?: number, partnerId?: number): Promise<SubscriptionOption[]> {
    return httpClientMicro
      .get(this.routes.mySubscriptionOptions(programPlanId), { headers: { 'x-partner-id': partnerId } })
      .then(({ data }) => data);
  }

  public cancelSubscription(subscriptionId: number, partnerId?: number): Promise<{}> {
    return httpClientMicro.delete(this.routes.cancelSubscription(subscriptionId), {
      headers: { 'x-partner-id': partnerId }
    });
  }

  public getSubscriptionDetails(programPlanId: number, partnerId: number): Promise<DetailedSubscription[]> {
    return httpClientMicro
      .get(this.routes.subscriptionDetails(programPlanId), {
        params: {
          isActive: false
        },
        headers: { 'x-partner-id': partnerId }
      })
      .then(({ data }) => data?.data);
  }

  public getPaymentIntent(subscriptionOptionId: number, partnerId?: number): Promise<{ clientSecret: string }> {
    return httpClientMicro
      .get(this.routes.paymentIntent(), {
        params: {
          subscriptionOptionId: subscriptionOptionId
        },
        headers: { 'x-partner-id': partnerId }
      })
      .then(({ data }) => data);
  }

  public getPaymentHistory(
    processorSubscriptionId: string,
    sort: Sort<keyof ProcessorPayment>,
    paginationOptions: Pagination = { skip: 0, take: 10, total: 0 }
  ): Promise<ApiListResponse<ProcessorPayment>> {
    return httpClientMicro
      .get(this.routes.paymentHistory(processorSubscriptionId), {
        params: { ...paginationOptions, ...formatSortParameter(sort) }
      })
      .then(({ data }) => data);
  }

  public getPaymentMethods(partnerId?: number): Promise<PaymentMethod[]> {
    return httpClientMicro
      .get(this.routes.paymentMethods(), { headers: { 'x-partner-id': partnerId } })
      .then(({ data }) => data);
  }

  public setupIntent(
    customerId?: string,
    paymentMethod?: string,
    partnerId?: number
  ): Promise<{ secret: string; customerId: string }> {
    return httpClientMicro
      .post(this.routes.setupIntent(), { customerId, paymentMethod }, { headers: { 'x-partner-id': partnerId } })
      .then(({ data }) => data.data);
  }

  public linkPartialVehicletoVehicle(partialVehicleId: number, vehicleId: number): Promise<{ message: string }> {
    return httpClientMicro.put(this.routes.partialVehicleAssociate(partialVehicleId), {
      railsVehicleId: vehicleId
    });
  }

  public updatePaymentMethod(
    processorSubscriptionId: string,
    paymentMethodId: string,
    partnerId?: number
  ): Promise<{ message: string }> {
    return httpClientMicro.put(
      this.routes.updatePaymentMethod(processorSubscriptionId),
      {
        paymentMethodId
      },
      { headers: { 'x-partner-id': partnerId } }
    );
  }

  public subscribeVOToPlanWithOption(request: CreateSubscriptionRequest): Promise<UserSubscriptionResponse> {
    return httpClientMicro
      .post(this.routes.subscribeWithOption(), request, { headers: { 'x-partner-id': request?.partnerId } })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw handleError(error);
      });
  }

  public getSubscriptionFaqs(
    sort: Sort<keyof ProgramPlanFaq>,
    paginationOptions: Pagination = { skip: 0, take: 100, total: 0 }
  ): Promise<ProgramPlanFaq[]> {
    return httpClientMicro
      .get(this.routes.subscriptionFaqs(), {
        params: { ...paginationOptions, ...formatSortParameter(sort) }
      })
      .then(({ data }) => data)
      .catch((error) => {
        throw error;
      });
  }

  public getSubscriptionsCheck(): Promise<{ result: boolean }> {
    return httpClientMicro.get(this.routes.check()).then(({ data }) => data);
  }
}
