







































































import Vue from 'vue';

import { UiSettings } from '@/store/types';

export default Vue.extend({
  name: 'OnrampNavigation',

  computed: {
    currentStepLabel(): string {
      if (this.currentStep === 'offer') {
        return 'estimates';
      } else if (this.currentStep === 'schedule') {
        return 'book';
      } else {
        return this.currentStep;
      }
    },

    currentStep(): string {
      return this.$route.meta?.step;
    },

    chevronDirection(): 'up' | 'down' {
      return this.uiSettings.showSidebar === true ? 'up' : 'down';
    },

    uiSettings(): UiSettings {
      return this.$store.getters['settings/getUiSettings'];
    },

    isVehicleFirstFlow(): boolean {
      return this.$store.getters['user/isLyftUser'];
    }
  },

  destroyed(): void {
    this.$store.dispatch('settings/setUiSettings', {
      showSidebar: false
    });
  },

  methods: {
    toggleSidebar(): void {
      const showSidebar = !this.uiSettings.showSidebar;

      this.$store.dispatch('settings/setUiSettings', {
        showSidebar: showSidebar
      });
    },

    isCompleted(step: string): boolean {
      if (this.isVehicleFirstFlow) {
        switch (step) {
          case 'services':
            if (
              this.currentStep === 'services' ||
              this.currentStep === 'confirm' ||
              this.currentStep === 'offer' ||
              this.currentStep === 'schedule'
            ) {
              return true;
            }
            return false;
          case 'estimate':
            if (this.currentStep === 'offer' || this.currentStep === 'confirm' || this.currentStep === 'schedule') {
              return true;
            }
            return false;
          case 'book':
            if (this.currentStep === 'confirm' || this.currentStep === 'schedule') {
              return true;
            }
            return false;
          case 'confirm':
            if (this.currentStep === 'confirm') {
              return true;
            }
            return false;
          default:
            return false;
        }
      } else {
        switch (step) {
          case 'vehicle':
            if (
              this.currentStep === 'vehicle' ||
              this.currentStep === 'confirm' ||
              this.currentStep === 'offer' ||
              this.currentStep === 'schedule' ||
              this.currentStep === 'sign-up'
            ) {
              return true;
            }
            return false;
          case 'estimate':
            if (this.currentStep === 'offer' || this.currentStep === 'confirm' || this.currentStep === 'schedule') {
              return true;
            }
            return false;
          case 'book':
            if (this.currentStep === 'confirm' || this.currentStep === 'schedule') {
              return true;
            }
            return false;
          case 'confirm':
            if (this.currentStep === 'confirm') {
              return true;
            }
            return false;
          default:
            return false;
        }
      }
    }
  }
});
