

















































import Vue, { PropType } from 'vue';
import { BModal, BCollapse, VBToggle } from 'bootstrap-vue';
import { JoinPlusBannerConfig } from '@/constants/plus-banner';
import PartnerService from '@/services/microservices/partnerService';

Vue.directive('b-toggle', VBToggle);

export default Vue.extend({
  name: 'JoinPlusBanner',

  components: {
    BModal,
    BCollapse
  },

  props: {
    bannerConfig: {
      type: Object as PropType<JoinPlusBannerConfig>,
      required: true
    },

    partnerName: {
      type: String,
      required: true
    },

    programName: {
      type: String,
      required: true
    },

    planName: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      submitting: false
    };
  },
  computed: {
    modalRef(): BModal {
      return this.$refs['joinPlusModal'] as BModal;
    },
    modalHtml(): string {
      return this.bannerConfig?.modalHtml || '';
    },
    modalHtmlExpanded(): string {
      return this.bannerConfig?.modalHtmlExpanded || '';
    }
  },

  methods: {
    showModal() {
      this.modalRef.show();
    },
    confirm(): void {
      this.submitting = true;
      const service = new PartnerService();
      service
        .subscribeVOToPlan(this.partnerName, this.planName)
        .then(() => this.joinSuccess())
        .catch(() => this.handleError());
    },
    joinSuccess(): void {
      this.$store.commit('onrampCart/reset');
      window.location.replace(`/plus/${this.programName}/${this.planName}/success`);
    },
    handleError(): void {
      this.$store.dispatch('alerts/addAlert', {
        message: 'Hmm, something went wrong. Sorry about that.',
        type: 'danger',
        timedDismiss: true
      });
    }
  }
});
