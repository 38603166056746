import store from '@/store/store';

export default function fetchBaseResources(): Promise<any> {
  return fetchRefreshToken()
    .catch(() => null) // not logged in, continue
    .finally(() =>
      Promise.allSettled([fetchProfile(), fetchUserResources(), fetchServices(), fetchPartnerConfig(), fetchFeatures()])
    );
}

function fetchProfile(): Promise<void> {
  return store.dispatch('user/fetchProfile').catch((error) => {
    if (error === 'redirecting') throw error;
    else return;
  });
}

function fetchServices(): Promise<void> {
  return store.dispatch('services/fetchServices');
}

function fetchUserResources(): Promise<void> {
  return store.dispatch('user/fetchRequiredResources');
}

function fetchPartnerConfig(): Promise<void> {
  return store.dispatch('config/fetchPartnerConfig');
}

function fetchFeatures(): Promise<void> {
  return store.dispatch('settings/fetchFeatures');
}

function fetchRefreshToken(): Promise<void> {
  return store.dispatch('auth/refreshToken');
}
