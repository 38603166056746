




















import Vue from 'vue';
import { BFormInput } from 'bootstrap-vue';
import { uniqueId, debounce } from 'underscore';

const debouncedValidator = debounce((validator: () => void): void => validator(), 250);

// TODO: `v-model` is broken for this component; there is no `email` prop. The
//       whole initialEmail/email business is janky and unnecessary, as well.
//       This can be majorly simplified. It's used in a lot of places, though,
//       so I'm not going to touch it in the PR that introduces this comment.

export default Vue.extend({
  name: 'EmailInput',

  components: {
    'b-form-input': BFormInput
  },

  model: {
    prop: 'email',
    event: 'input'
  },

  props: {
    initialEmail: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    hasFocus: {
      type: Boolean,
      default: false
    },

    floatingLabels: {
      type: Boolean,
      default: true
    },

    placeholder: {
      type: String,
      default: ''
    },

    labelTitle: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      email: this.initialEmail,
      emailValid: false,
      emailValidated: false
    };
  },

  computed: {
    showEmailError(): boolean {
      return this.emailValidated === true && this.emailValid === false;
    },

    id(): string {
      return uniqueId('email');
    }
  },

  mounted(): void {
    if (this.email.length > 0) {
      this.validateEmail();
    }
    if (this.hasFocus) {
      (this.$refs['email-input'] as BFormInput).focus();
    }
  },

  methods: {
    emailUpdated(_e: Event) {
      this.$emit('email-update', this.email);
      this.validateEmail();
    },

    validateEmail(): void {
      debouncedValidator(() => {
        if (this.email.match(/^\S*@\S*\.\S{1,}$/)) {
          this.emailValid = true;
        } else {
          this.emailValid = false;
        }
        this.emailValidated = true;
        this.$emit('email-valid-update', this.emailValid);
      });
    }
  }
});
