










































































//Libraries
import Vue, { PropType } from 'vue';
import Fuse from 'fuse.js';
import { isMundaneObject } from '../../utilities';

//Types
import { JsonObject } from '@/types/JsonSpec';
import { Service } from '@/types/Services';

export default Vue.extend({
  name: 'SearchList',

  props: {
    containerHeight: {
      type: Number,
      default: 300
    },

    hoverResults: {
      type: Boolean,
      default: false
    },

    placeholder: {
      type: String,
      default: 'type your search term'
    },

    searchData: {
      type: Array as PropType<(JsonObject | string)[]>,
      default: () => []
    },

    defaultData: {
      type: Array as PropType<(JsonObject | string)[]>,
      default: () => []
    },

    defaultDataLabel: {
      type: String,
      default: ''
    },

    searchKeys: {
      type: Array as PropType<string[]>,
      default: () => ['name', 'id']
    },

    fuseSearchOptions: {
      type: Object,
      default: () => ({
        threshold: 0.2,
        tokenize: true,
        maxPatternLength: 64
      })
    },

    showInitialData: {
      type: Boolean,
      default: true
    },

    showButton: {
      type: Boolean,
      default: false
    },

    homepage: {
      type: Boolean,
      default: true
    },

    searchType: {
      type: String,
      default: ''
    },

    defaultDataHover: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      searchResults: [] as any[],
      searchTerm: '',
      selectedResult: false,
      focusedItem: {} as JsonObject,
      showDefaultData: this.showInitialData,
      onDefaultData: false,
      showItems: true
    };
  },

  computed: {
    isLyftUser(): boolean {
      return this.$store.getters['user/isLyftUser'];
    },

    searchDataLocal(): JsonObject[] {
      return this.searchData.map((data) => {
        return isMundaneObject(data)
          ? data
          : {
              id: 0,
              name: data
            };
      });
    },

    searchPackageDataLocal(): any[] {
      return this.searchDefaultData.filter((service) => service.note === 'Lyft Driver Rewards program service');
    },

    searchDefaultData(): JsonObject[] {
      return this.defaultData.map((data) => {
        return isMundaneObject(data)
          ? data
          : {
              id: 0,
              name: data
            };
      });
    },

    fuse(): Fuse<JsonObject, Fuse.FuseOptions<any>> {
      let options: Fuse.FuseOptions<any> = {
        ...this.fuseSearchOptions,
        keys: this.searchKeys,
        minMatchCharLength: 2,
        threshold: 0.3
      };

      return new Fuse(this.searchDataLocal, options);
    },

    showData(): boolean {
      return this.searchTerm.length >= 2;
    }
  },

  watch: {
    defaultDataHover(newValue) {
      if (newValue && this.onDefaultData) {
        this.onDefaultData = true;
      } else {
        this.onDefaultData = false;
      }
    }
  },

  methods: {
    handleActiveState() {
      if (!this.onDefaultData && !this.defaultDataHover) {
        this.showDefaultData = false;
      }
      this.showItems = !this.selectedResult;
      if (this.searchTerm === '') {
        this.$emit('is-active', false);
        this.$emit('clear-selected-service');
        this.selectedResult = false;
        (this.$refs.SearchResultsContainer as HTMLElement).style.height = `300px`;
      } else {
        let height = this.containerHeight > 600 ? 300 : this.containerHeight;
        (this.$refs.SearchResultsContainer as HTMLElement).style.height = `${height}px`;
      }
    },

    performSearch(): any {
      //TODO Debounce this, probably use underscore
      if (this.searchTerm.length >= 2) {
        this.showDefaultData = false;
        this.showItems = !this.selectedResult;
        this.$emit('is-active', true);
        (this.$refs.SearchResultsContainer as HTMLElement).style.height = `${300}px`;
        this.searchResults = this.fuse.search(this.searchTerm);
      } else {
        this.showDefaultData = true;
        this.selectedResult = false;
        this.$emit('is-active', false);
        this.$emit('clear-selected-service');
      }
    },

    selectTopResult() {
      (this.$refs.searchResults as HTMLElement[])[0].click();
    },

    selectItem(item: JsonObject) {
      if (item.id === 0) {
        item = item.name;
      }

      this.$emit('item-selected', item);
      this.$emit('is-active', false);
      this.blurSearchMenu();
      this.searchTerm = '';
      this.searchTerm = item.name || item.answer;
      this.selectedResult = true;
      this.showItems = false;
    },

    selectDefaultData(event: Event, item: JsonObject) {
      this.$emit('default-item-selected', item);

      this.blurSearchMenu();
      this.showDefaultData = false;
      this.searchTerm = !this.isLyftUser ? item.answer || item.name : `${item.year} ${item.make} ${item.model}`;
      this.showItems = false;
      this.selectedResult = true;
    },

    formatCategoryName(item: JsonObject) {
      return item.categoryName ? `${item.categoryName}` : '';
    },

    handleDefaultData() {
      if (this.searchTerm.length < 2 && !this.showDefaultData) {
        this.showDefaultData = true;
      }
    },

    formatSearchResult(item: Service): string {
      const term = item.name;
      const searchTerms = this.searchTerm
        .split(/\s+/)
        .map((item) => item.replace(/\W/g, ''))
        .filter((item) => !!item);
      const toReplace = new RegExp(searchTerms.join('|'), 'ig');
      const result = searchTerms.length > 0 ? term.replace(toReplace, `<strong>$&</strong>`) : term;

      return result;
    },

    blurSearchMenu(): void {
      this.$nextTick(() => {
        if (this.$refs['SearchListInput'] !== undefined) {
          (this.$refs.SearchListInput as HTMLElement).focus();
          (this.$refs.SearchListInput as HTMLElement).blur();
        }
      });
    }
  }
});
