








import Vue from 'vue';

export default Vue.extend({
  name: 'Spinner',

  props: {
    relative: {
      type: Boolean,
      default: false
    },

    text: {
      type: String,
      required: false,
      default: 'Loading...'
    },

    size: {
      type: String,
      required: false,
      default: 'lg'
    },

    hideBackground: {
      type: Boolean,
      required: false,
      default: false
    }
  }
});
