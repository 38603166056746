import { SegmentUserType } from '@/enums/SegmentUserType';
import userService from '@/services/userService';
import store from '@/store/store';
import { OfferFull } from '@/types/Offer';
import {
  createSegmentTraits,
  SegmentOfferProps,
  SegmentServiceRequestProps,
  SegmentTraits
} from '@/types/SegmentTraits';
import { ServiceRequest } from '@/types/ServiceRequest';
import { User } from '@/types/User';
import { isNullish } from '@/utilities';

const getOwnedVehicle = (serviceRequest: ServiceRequest) =>
  store.getters['user/getOwnedVehicle'](serviceRequest.ownedVehicleId);

export default {
  // This is a Mixpanel specific API call. It should only be called once, right before you call identifyUser for the first time (likely after registering).
  // TBD:  Once the redesign project is launched, alias should be called when the transient user is created.
  aliasUser(openbayId: string): void {
    analytics.alias(openbayId);
  },
  // Convert response data into traits then call Segment identify
  // We are not logging an private user information just openbay_id and type of user
  identifyUserWithData(data: User, description: SegmentUserType, traits?: Record<string, unknown>): void {
    const identifyTraits = createSegmentTraits({
      id: data.openbayId,
      description: description,
      createdAt: data.createdAt
    });

    if (!isNullish(traits)) Object.assign(identifyTraits, traits);

    this.identifyUser(data.openbayId, identifyTraits);
  },
  // Identifies user with Segment.  Call after a user register, log in, updates their info
  // `id` must be the unique server side id created for the user object
  identifyUser(id: string, traits: SegmentTraits): void {
    analytics.identify(id, traits);
  },
  trackGeneral(eventName: string, properties: any = null): void {
    analytics.track(eventName, properties);
  },
  // prettier-ignore
  trackServiceRequest(eventName: string, serviceRequest: ServiceRequest, additionalProperties: any = null): void {
    const props = {
      ...setSegmentServiceRequestProps(serviceRequest),
      ...additionalProperties
    };

    analytics.track(eventName, props);
  },
  trackServiceRequestAndOffer(eventName: string, serviceRequest: ServiceRequest, offer: OfferFull): void {
    const serviceRequestProps = setSegmentServiceRequestProps(serviceRequest);
    const offerProps = setSegmentOfferProps(offer);
    const segmentProps = {
      ...serviceRequestProps,
      ...offerProps
    };

    analytics.track(eventName, segmentProps);
  },
  // Retrieve user profile from server
  // Package response into segment traits and
  // update with segment identify
  initializeSegmentIdentify() {
    userService
      .retrieveUserProfile()
      .then((data) => {
        const retrievedUser = data as User;
        const isPartnerDriver = data.isLyftDriver;

        const traits: SegmentTraits = {
          id: String(retrievedUser.id),
          createdAt: retrievedUser.createdAt
        };
        if (isPartnerDriver) {
          traits.description = SegmentUserType.RegisteredLyft;
        } else {
          traits.description = SegmentUserType.RegisteredOpenbay;
        }

        analytics.identify(traits);
      })
      .catch((error) => {
        return error;
      });
  }
};

//TODO: Centralize this formatter somewhere
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

function setSegmentServiceRequestProps(serviceRequest: ServiceRequest): SegmentServiceRequestProps {
  const services = serviceRequest.requestedServices.map((service) => service.name);
  const offerCount = serviceRequest.offers ? serviceRequest.offers.length : 0;
  const totalPrice = !isNullish(serviceRequest.acceptedOffer)
    ? formatter.format(serviceRequest.acceptedOffer.totalPriceInDollars)
    : '';
  const ownedVehicle = getOwnedVehicle(serviceRequest);

  return {
    metro_id: serviceRequest.metroId,
    service_request_id: serviceRequest.openbayId,
    service_request_service: services, // May need to be converted to array of strings from name prop
    service_request_service_count: services.length,
    service_request_offer_count: offerCount,
    service_request_total_price: totalPrice,
    vehicle_year: ownedVehicle.year,
    vehicle_make: ownedVehicle.make,
    vehicle_model: ownedVehicle.model,
    vehicle_mileage: ownedVehicle.mileage
  };
}

function setSegmentOfferProps(offer: OfferFull): SegmentOfferProps {
  return {
    offer_id: offer.openbayId,
    sp_company_id: offer.location.companyId,
    sp_company_name: offer.location.companyName,
    sp_location_id: offer.location.id,
    sp_location_name: offer.location.name,
    sp_location_city: offer.location.city,
    sp_location_state: offer.location.state,
    sp_location_zipcode: offer.location.zipcode
  };
}
