import { httpClient } from '@/api';
import { handleError, handleSuccess } from '@/services/helpers';
import store from '@/store/store';
import { Thread } from '@/types/resources/Messages';
import { MessageThreadResource, MessageThreadsResource } from '@/types/resources/MessageThreads';
import { isNullish } from '@/utilities';

export default {
  fetchMessageThreads(): Promise<any> {
    return httpClient
      .get<MessageThreadsResource>('v3/message_threads')
      .then((response) => {
        // TODO: Unread count should be handled in store action
        const messageThreads = handleSuccess(response);
        const unreadCount = messageThreads.filter((thread: Thread) => thread.unreadCount > 0).length;
        store.dispatch('user/setUnreadCount', unreadCount);
        return messageThreads;
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  /**
   * Send a message to a thread.
   *
   * @remarks
   * This endpoint needs to be refactored.
   *
   * @param threadId - The ID of the thread to send a message to. Not requireid if recipientId is provided.
   * @param body - The body of the message as a string.
   * @param referenceId - The ID of the service request the message is regarding. Note it doesn't accept offers.
   * @param recipentId - The ID of the user this message will be sent to. Required if no threadId is supplied.
   * @param recipentType - The type of the user this message will be sent to. The backend has this in the notes as options: User, MemberServices, ServiceStation::Location
   * @returns If successful, a promise containing the thread and it's messages.
   *          If not successful, a string error message.
   *
   */
  sendMessage(
    threadId: number | null,
    body: string,
    referenceId: number,
    recipientId: number | null = null,
    recipientType: string | null = null
  ) {
    return httpClient
      .post<MessageThreadResource>('v3/messages', {
        threadId: threadId,
        body: body,
        referenceId: referenceId,
        ...(!isNullish(recipientId) && { recipientId }),
        ...(!isNullish(recipientType) && { recipientType })
      })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  /**
   * Updates a thread as read, and returns the thread with its messages.
   *
   * @remarks
   * This is how we retrieve a thread's latest messages, but it's mixing concerns.
   *
   * @param threadId - The ID of the thread to update.
   * @returns If successful, a promise containing the thread and its messages.
   *          If not successful, a string error message.
   *
   */
  markThreadRead(threadId: number): Promise<any> {
    return httpClient
      .put<MessageThreadResource>(`v3/message_threads/${threadId}`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },
  syncThread(syncToken: string, page: number, maxPerPage?: number): Promise<any> {
    return httpClient.get('v3/message_thread', {
      params: { syncToken: syncToken, page: page, maxPerPage: maxPerPage }
    });
  }
};
