import { PartnerConfig } from '@/store/types';
import Vue, { PropType } from 'vue';

const joinPlusMixin = Vue.extend({
  props: {
    partnerConfig: {
      type: Object as PropType<PartnerConfig>,
      required: false,
      default: null
    }
  },

  computed: {
    programNameSlug(): string {
      return this.$route.params.programName?.toLowerCase() || '';
    },

    partnerConfigFromRoute(): PartnerConfig {
      return this.$store.getters['config/getPartnerConfig'](this.programNameSlug);
    }
  }
});

export { joinPlusMixin };
