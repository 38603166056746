




import { kebabCase } from 'lodash';
import { uniq } from 'underscore';

import { Program } from '@/types/microservices/Program';
import { Subscription } from '@/types/microservices/Subscriptions';
import { selectProgram } from '@/utilities';
import CombinedComponent, { userMixin } from '@/mixins';

const defaultLogoUrl = (): string => {
  return `/assets/logo.svg`;
};

const defaultPlusLogoUrl = (): string => {
  return require(`@/assets/images/ob+_logo_nav.svg`);
};

const lyftLogoUrl = (): string => {
  return require(`@/assets/images/logo-lockup_Openbay-Lyft.svg`);
};

const isLandingPage = (): boolean => {
  // check that our very first page hit is a landing page
  // so we don't temporarily flash the ob logo.
  return !!window.location.pathname.match(/plus/);
};

export default CombinedComponent(userMixin).extend({
  name: 'Logo',

  data() {
    return {
      defaultLogoUrl: defaultLogoUrl(),
      isLandingPage: isLandingPage(),
      logoError: false
    };
  },

  computed: {
    isLyftUser(): boolean {
      return this.$store.getters['user/isLyftUser'];
    },

    showPartnerLogo(): boolean {
      const activeSubscription = this.$store.getters['user/getActiveSubscription'];
      const programs: Program[] = this.programs || [];
      const subscriptions: Subscription[] = this.subscriptions || [];
      const uniqueSubscriptions = uniq(subscriptions, (s) => s.program?.name);
      return uniqueSubscriptions.length == 1 || activeSubscription || programs.length;
    },

    isSubscriptionRoute(): boolean {
      if (this.isLandingPage) return true;
      return !!(this.$route.name?.match(/subscription/) && this.$route.params?.programName);
    },

    activeProgramSlug(): string {
      const activeSubscription = this.$store.getters['user/getActiveSubscription'];

      // Multiple subscriptions - show logo corresponding to the vehicle the user
      // is currently working with.
      if (activeSubscription) {
        return activeSubscription.program.name;
      }

      // Otherwise, show the logo for the first subscription in the list.
      if (this.subscriptions.length) {
        return this.subscriptions[0].program.name;
      }

      // If the user has multiple programs/partners, show the logo for the first program that is NOT
      // the default internal program.
      if (this.programs.length > 1) {
        return selectProgram(this.programs).name;
      }

      // In all other cases pick the first program in the list.
      return this.programs[0].name || '';
    },

    programSlugFromPath(): string | undefined {
      // MPC-5583: read program slug from window to prevent normal OB logo briefly appearing instead of the
      // partners logo.
      const parsedPath = /\/plus\/([A-Za-z\-[^0-9]+)\/.+/gi.exec(window.location.pathname);
      if (parsedPath && parsedPath[1]) return parsedPath[1];
      return undefined;
    },

    programSlugFromRoute(): string | undefined {
      return this.$route.params.programName || this.programSlugFromPath;
    },

    showAutoRepairBranding(): boolean {
      const isAppointmentRoute = this.$route.meta?.appointment || this.$route.meta?.appointmentNav;
      return !!this.autoRepairPartner && isAppointmentRoute;
    },

    autoRepairPartner(): string {
      // kebabCase on partner name - partners don't have a slug
      // like programs do so in order to find the correct logo we gotta
      // slugify it.
      return kebabCase(this.$store.getters['appointment/getPartner']);
    },

    logoUrl(): string {
      if (this.isSubscriptionRoute && this.programSlugFromRoute) return this.partnerLogoUrl(this.programSlugFromRoute);
      if (this.showAutoRepairBranding) return this.partnerLogoUrl(this.autoRepairPartner);
      if (this.isLyftUser) return lyftLogoUrl();
      if (this.showPartnerLogo) return this.partnerLogoUrl(this.activeProgramSlug);
      if (this.subscriptions?.length) return defaultPlusLogoUrl();
      return defaultLogoUrl();
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.isLandingPage = isLandingPage();
      }
    }
  },

  methods: {
    onLogoError(): void {
      this.logoError = true;
    },
    partnerLogoUrl(programSlug: string): string {
      if (!programSlug || this.logoError) {
        if (this.subscriptions?.length) return defaultPlusLogoUrl();
        else return defaultLogoUrl();
      }
      const filename = this.subscriptions?.length ? 'logo_plus.svg' : 'logo.svg';
      return `/assets/partners/${programSlug}/${filename}`;
    }
  }
});
