import { httpClient } from '@/api';
import { handleError, handleSuccess } from '@/services/helpers';
import { OfferFull, OffersResource } from '@/types/Offer';
import { AppointmentSlotsDocument } from '@/types/resources/AppointmentProposals';
import { ServiceAddress, ServiceRequestDocument, ServiceRequestParameters } from '@/types/ServiceRequest';

export default {
  create(serviceRequest: ServiceRequestParameters) {
    return httpClient
      .post<ServiceRequestDocument>(`v3/service_requests/`, {
        serviceRequest: serviceRequest
      })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  cancel(serviceRequestId: number, withdrawReason: string, withdrawReasonDescription: string, comeBack: string) {
    return httpClient
      .delete<ServiceRequestDocument>(`v3/service_requests/${serviceRequestId}`, {
        params: {
          reason_withdrawn: withdrawReason,
          reason_withdrawn_description: withdrawReasonDescription,
          come_back: comeBack
        }
      })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  unacceptOffer(serviceRequestId: number, offerId: number, reasonUnaccepted: string) {
    return httpClient
      .put<ServiceRequestDocument>(`v3/service_requests/${serviceRequestId}/offers/${offerId}/unaccept`, {
        reason_unaccepted: reasonUnaccepted
      })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  get(id: number | string) {
    return httpClient
      .get<ServiceRequestDocument>(`v3/users/service_requests/${id}`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  /**
   *
   *
   *
   * @param serviceRequestId - The ID of the service request to fetch offers for.
   * @returns If successful, a promise containing an array of offers.
   *          If not successful, a string error message.
   *
   */
  fetchOffers(serviceRequestId: number) {
    return httpClient
      .get<OffersResource>(`v3/service_requests/${serviceRequestId}/offers`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  fetchAppointmentSlots(serviceRequestId: number, locationId: number) {
    return httpClient
      .get<AppointmentSlotsDocument>(
        `v3/users/service_requests/${serviceRequestId}/appointment_proposals/${locationId}`
      )
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  /**
   *
   *
   *
   * @param id - The ID of the service request to reschedule.
   * @returns If successful, a promise containing the updated service request.
   *          If not successful, a string error message.
   *
   */
  proposeAppointmentSlot(serviceRequestId: number, appointmentTimes: string[], isInstantBooking: boolean) {
    return httpClient
      .put<ServiceRequestDocument>(`v3/users/service_requests/${serviceRequestId}/appointments`, {
        srId: serviceRequestId,
        slotKey: appointmentTimes,
        autoschedule: isInstantBooking
      })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  /**
   * Fetch the existing latest proposed times for a service request.
   *
   *
   * @param id - The ID of the service request to fetch appointments for.
   * @returns If successful, a promise containing appointment proposal times.
   *          If not successful, a string error message.
   *
   */
  fetchAppointmentProposals(id: number): Promise<any> {
    return httpClient
      .get<AppointmentSlotsDocument>(`v3/users/service_requests/${id}/appointments`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  /**
   * Reject the latest proposed times for a service request.
   *
   *
   * @param id - The ID of the service request to reject appointments for.
   * @returns If successful, a promise containing the udpated service request.
   *          If not successful, a string error message.
   *
   */
  rejectAppointmentProposals(id: number) {
    return httpClient
      .delete<ServiceRequestDocument>(`v3/users/service_requests/${id}/appointments`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  /**
   * Accept a proposed times for a service request.
   *
   *
   * @param id - The ID of the proposal to be accepted
   * @returns If successful, a promise containing the updated service request.
   *          If not successful, a string error message.
   *
   */
  acceptAppointmentProposal(id: number) {
    return httpClient
      .post<ServiceRequestDocument>(`v3/users/service_requests/appointments/`, {
        appointmentId: id
      })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  acceptOffer(offer: OfferFull, cardId: number | null, address: ServiceAddress | null, appointmentTime: string) {
    return httpClient
      .put<ServiceRequestDocument>(`v3/service_requests/${offer.serviceRequestId}/offers/${offer.id}`, {
        srId: offer.serviceRequestId,
        id: offer.id,
        creditCardId: cardId,
        rewards: offer.maxRewards * 100,
        serviceAddress: address,
        appointmentTime: appointmentTime
      })
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  }
};
