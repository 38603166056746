/* eslint-disable no-param-reassign */
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import creditCardService from '@/services/creditCardService';
import SubscriptionService from '@/services/microservices/subscriptionService';
import { CreditCard } from '@/types/CreditCard';
import { PaymentMethod } from '@/types/microservices/PaymentMethod';
import { isNullish } from '@/utilities';

import { CreditCardsState, RootState } from '../types';

const state = (): CreditCardsState => ({
  creditCards: [] as CreditCard[],
  paymentMethods: [] as PaymentMethod[],
  error: false
});

const getters: GetterTree<CreditCardsState, RootState> = {
  getCards: (state) => state.creditCards,
  getPaymentMethods: (state) => state.paymentMethods
};

const actions: ActionTree<CreditCardsState, RootState> = {
  fetchCreditCards({ commit }) {
    return creditCardService
      .retrieveSavedCards()
      .then((creditCards) => {
        const filteredCards = creditCards.filter((creditCard: CreditCard) => !isNullish(creditCard.type));
        commit('setCreditCards', filteredCards);
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchPaymentMethods({ commit }) {
    return new SubscriptionService()
      .getPaymentMethods()
      .then((paymentMethods) => {
        commit('setPaymentMethods', paymentMethods);
      })
      .catch((error) => {
        throw error;
      });
  },
  addCreditCard({ commit }, stripeToken: stripe.Token) {
    return creditCardService
      .saveCard(stripeToken)
      .then((response) => {
        commit('addCreditCard', response);
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
  removeCreditCard({ commit }, creditCard: CreditCard) {
    return creditCardService
      .deleteSavedCard(creditCard.id)
      .then(() => {
        commit('removeCreditCard', creditCard);
      })
      .catch((error) => {
        throw error;
      });
  }
};

const mutations: MutationTree<CreditCardsState> = {
  setCreditCards(state, creditCards: CreditCard[]) {
    state.creditCards = creditCards;
  },
  setPaymentMethods(state, paymentMethods: PaymentMethod[]) {
    state.paymentMethods = paymentMethods;
  },
  addCreditCard(state, creditCard: CreditCard) {
    state.creditCards.push(creditCard);
  },
  removeCreditCard(state, creditCardToRemove: CreditCard) {
    state.creditCards = state.creditCards.filter((creditCard: CreditCard) => creditCard.id !== creditCardToRemove.id);
  }
};

const CreditCardsStore: Module<CreditCardsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default CreditCardsStore;
