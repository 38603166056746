


































import { BFormTags } from 'bootstrap-vue';
import CombinedComponent, { joinPlusMixin } from '@/mixins';

type CompanyField = { label: string; checked: boolean; input?: string };

export default CombinedComponent(joinPlusMixin).extend({
  name: 'JoinPlusDeliveryFields',

  components: {
    BFormTags
  },

  data() {
    return {
      showOtherInput: false,
      otherInput: [] as string[],
      mergedResults: [] as string[],
      groups: [
        [
          { label: 'Uber Eats', checked: false, program: '' },
          { label: 'DoorDash', checked: false, program: '' },
          { label: 'Amazon Flex', checked: false, program: 'amazon-flex' }
        ],
        [
          { label: 'Grubhub', checked: false, program: '' },
          { label: 'Instacart', checked: false, program: '' },
          { label: 'Shipt', checked: false, program: '' }
        ],
        [
          { label: 'Gopuff', checked: false, program: 'gopuff' },
          { label: 'Postmates', checked: false, program: '' },
          { label: 'Favor Delivery', checked: false, program: '' }
        ],
        [
          { label: 'UPS', checked: false, program: '' },
          { label: 'Bite Squad', checked: false, program: '' },
          { label: 'Driver.com', checked: false, program: '' }
        ],
        [{ label: 'Other', checked: false, program: '' }]
      ]
    };
  },

  created() {
    this.setCheckedByProgram(this.programNameSlug);
  },

  methods: {
    checkChanged(field: CompanyField) {
      if (field.label == 'Other') this.showOtherInput = field.checked;

      this.emitChanges();
    },

    emitChanges() {
      const selected = this.groups
        .flat()
        .filter((f) => f.checked)
        .map((s) => s.label);

      if (this.showOtherInput) {
        this.mergedResults = [...selected, ...this.otherInput];
      } else {
        this.mergedResults = [...selected];
      }

      this.$emit('on-fields-changed', [...this.mergedResults]);
    },

    setCheckedByProgram(program: string): void {
      const field = this.groups.flat().find((f) => f.program === program);
      if (field) {
        field.checked = true;
        this.emitChanges();
      }
    }
  }
});
