import { httpClient } from '@/api';
import { handleError, handleSuccess } from '@/services/helpers';
import { GenericMessage } from '@/types/API';
import { OwnedVehicle, OwnedVehicleDocument } from '@/types/resources/OwnedVehicles';
import { ServiceRequestsDocument } from '@/types/ServiceRequest';

export default {
  fetchServiceRequests(
    id: number,
    scope: 'settled' | 'withdrawn' | 'expired' | 'pending' | 'accepted' | '' = '',
    page: number = 1,
    perPage: number = 5,
    all: boolean = false
  ) {
    return httpClient
      .get<ServiceRequestsDocument>(`v3/owned_vehicles/${id}/service_requests/${scope}`, {
        params: { page: page, per_page: perPage, all: all }
      })
      .then((response) => {
        const serviceRequestResponse = {
          serviceRequests: handleSuccess(response),
          count: response.data.meta!.count
        };
        return serviceRequestResponse;
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  updateOwnedVehicle(ownedVehicle: Partial<OwnedVehicle>) {
    return httpClient
      .patch<OwnedVehicleDocument>(`v3/owned_vehicles/${ownedVehicle.id}`, ownedVehicle)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  updateOwnedVehicleInfo(vehicleId: number, payload: Partial<OwnedVehicle>) {
    return httpClient
      .put<OwnedVehicleDocument>(`v3/owned_vehicles/${vehicleId}`, payload)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        return handleError(error);
      });
  },

  deleteOwnedVehicle(vehicleId: number) {
    return httpClient
      .delete<GenericMessage>(`v3/owned_vehicles/${vehicleId}`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        return handleError(error);
      });
  }
};
