import { fetchConfig } from '@/init/app-config';
import { Subscription } from '@/types/microservices/Subscriptions';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

type UserProperties = { [key: string]: any };

export class GtagService {
  dataLayer = window.dataLayer || [];

  constructor() {
    this.gtag('js', new Date());
    this.gtag('config', fetchConfig().get('VUE_APP_GA_MEASUREMENT_ID'));
  }

  gtag(..._args: any[]) {
    // Google tag manager requires use of the `arguments` object.
    /* eslint-disable prefer-rest-params */
    this.dataLayer.push(arguments);
  }

  setUserProperties(props: UserProperties) {
    this.gtag('set', 'user_properties', props);
  }

  event(name: string, params: Record<string, any> = {}) {
    this.gtag('event', name, params);
  }

  setPartner(subscriptions: Subscription[]) {
    if (!subscriptions.length) return;

    this.setUserProperties({
      partner_name: subscriptions.map((s) => s.program.name).join(', ')
    });
  }
}

export let gtagService: GtagService;

export const initGtag = () => {
  if (gtagService instanceof GtagService) return gtagService;
  else {
    gtagService = new GtagService();
    return gtagService;
  }
};
