import { httpClient } from '@/api';
import { handleError } from '@/services/helpers';
import { TargetMarket, ZipcodeLocation } from '@/types/resources/ZipcodeLocations';

export default {
  searchByZipcode(zipcode: string) {
    return httpClient
      .get<ZipcodeLocation[]>('v1/zipcode/search', { params: { term: zipcode } })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw handleError(error);
      });
  },

  isInTargetMarket(zipcode: string): Promise<boolean> {
    return httpClient
      .get<TargetMarket>('v1/zipcode/target_market', { params: { zipcode } })
      .then(({ data }) => data.in_market)
      .catch(() => false); // returns a 403 for non-existent zips (instead of `{ in_market: false }`)
  }
};
