import { httpClientMicro } from '@/api-microservices';
import { Config, fetchConfig } from '@/init/app-config';
import { ApiListResponse } from '@/types/microservices/api';
import { Partner } from '@/types/microservices/Partner';
import { PartnerOffer } from '@/types/microservices/PartnerOffer';

export default class PartnerService {
  config: Config;
  routes = {
    myPartners: () => `${this.environmentPath()}/v1/my-partners`,
    mySubscriptions: () => `${this.environmentPath()}/v1/my-subscriptions`,
    myOffers: (partnerId: number) => `${this.environmentPath()}/v1/my-offers/${partnerId}`,
    myAdminPartners: () => `${this.environmentPath()}/v1/my-partners/admin/list`,
    partnerForProgramAndPlan: (programName: string, planName: string) =>
      `${this.environmentPath()}/v1/my-partners/by-program-and-plan/${programName}/${planName}`
  };
  constructor() {
    this.config = fetchConfig();
  }

  environmentPath() {
    return this.config.get('VUE_APP_PARTNER_API_BASE_URL');
  }

  public getMyPartners(): Promise<Partner[]> {
    return httpClientMicro.get(`${this.routes.myPartners()}`).then((response) => response.data);
  }

  public getPartnerForProgramAndPlan(programName: string, planName: string): Promise<Partner> {
    return httpClientMicro
      .get(`${this.routes.partnerForProgramAndPlan(programName, planName)}`)
      .then((response) => response.data);
  }

  public subscribeVOToPlan(partnerName: string, planNameSlug: string): Promise<{ message: string }> {
    return httpClientMicro.post(`${this.routes.mySubscriptions()}/${partnerName}/${planNameSlug}`);
  }

  public myOffers(partnerId: number): Promise<ApiListResponse<PartnerOffer>> {
    return httpClientMicro.get(`${this.routes.myOffers(partnerId)}`).then((response) => response.data);
  }

  public myAdminPartners(): Promise<any> {
    return httpClientMicro.get(`${this.routes.myAdminPartners()}`).then((response) => response.data);
  }
}
