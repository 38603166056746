/* eslint-disable no-param-reassign */
import Vue from 'vue';

import { AxiosError } from 'axios';
import localforage from 'localforage';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import router from '@/router';
import authService from '@/services/authService';
import { gtagService } from '@/services/gtagService';
import CoverageService from '@/services/microservices/coverageService';
import PartnerService from '@/services/microservices/partnerService';
import SubscriptionService from '@/services/microservices/subscriptionService';
import ownedVehicleService from '@/services/ownedVehicleService';
import segmentServices from '@/services/segmentServices';
import userService from '@/services/userService';
import zipcodeService from '@/services/zipcodeService';
import { Partner } from '@/types/microservices/Partner';
import { PartnerOffer } from '@/types/microservices/PartnerOffer';
import { Program } from '@/types/microservices/Program';
import { SubscriptionOption } from '@/types/microservices/SubscriptionOption';
import { Subscription } from '@/types/microservices/Subscriptions';
import { OwnedVehicle } from '@/types/resources/OwnedVehicles';
import { PartialVehicle } from '@/types/resources/PartialVehicle';
import { CompletePartialUserRequest, CreateUserRequest, User } from '@/types/User';
import { VehicleRequest } from '@/types/Vehicle';
import { ZipcodeLocation } from '@/types/ZipcodeLocation';
import { isNullish, isSubscriptionActive, selectProgram } from '@/utilities';

import { RootState, UserState } from '../types';

const initialState = (): UserState => ({
  hasServiceRequests: false,
  userProfile: {
    createdAt: '',
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    contactViaTextMessage: false,
    status: '',
    openbayId: '',
    uid: '',
    unreadCount: 0,
    usedRewards: '0.00',
    rewards: '0.00',
    earnedRewards: '0.00',
    isLyftDriver: false,
    hasPassword: false,
    confirmed: false
  } as User,
  ownedVehicles: [] as OwnedVehicle[],
  partialVehicles: [] as PartialVehicle[],
  loggedIn: false,
  location: {
    city: '',
    state: '',
    zipcode: '',
    term: ''
  } as ZipcodeLocation,
  subscriptions: [],
  programs: [],
  partners: [],
  subscriptionsLoaded: false,
  activeSubscription: undefined,
  partnerOffers: [],
  subscriptionOptions: [],
  hasInitialSubscription: false
});

const state = initialState();

const getters: GetterTree<UserState, RootState> = {
  getUserProfile: (state) => state.userProfile,

  getEmail: (state) => state.userProfile.email,

  getDisplayName: (state) => {
    let firstName = state.userProfile.firstName;
    let lastName = state.userProfile.lastName;
    const hasVehicle = state.ownedVehicles.length > 0;
    const emptyFirstName =
      isNullish(firstName) ||
      firstName === '';
    const emptyLastName = isNullish(lastName) || lastName === '';
    const vehicleName = hasVehicle ? state.ownedVehicles[0].make : 'Vehicle';
    firstName = emptyFirstName ? vehicleName : firstName;
    lastName = emptyLastName ? 'Owner' : lastName;

    return `${firstName} ${lastName}`.trim();
  },

  getHasServiceRequests: (state) => state.hasServiceRequests,

  getOwnedVehicle: (state) => (id: number) => {
    const vehicle = state.ownedVehicles.find((vehicle) => vehicle.id === id);
    return vehicle;
  },

  getOwnedVehicles: (state) => state.ownedVehicles,

  getPartialVehicles: (state) => state.partialVehicles,

  getSubscriptions: (state) => state.subscriptions,

  getPrograms: (state) => state.programs,

  getPartners: (state) => state.partners,

  getPartnerOffers: (state) => state.partnerOffers,

  getSubscriptionsLoaded: (state) => state.subscriptionsLoaded,

  getActiveSubscription: (state) => state.activeSubscription,

  getVehicleName: (state) => (id: number) => {
    const vehicle = state.ownedVehicles.find((vehicle) => vehicle.id === id);
    return !isNullish(vehicle) ? `${vehicle.year} ${vehicle.make} ${vehicle.model}` : '';
  },

  hasContactMethod: (state) =>
    (state.userProfile.email !== '') ||
    (state.userProfile.phoneNumber !== '' && state.userProfile.contactViaTextMessage),

  hasSubscription: (state) => state.subscriptions.length > 0,

  hasActiveSubscription: (state) => {
    if (state.subscriptions.length == 0) return false;

    return state.subscriptions.some((subscription) => isSubscriptionActive(subscription));
  },

  getActiveSubscriptions: (state) => {
    return state.subscriptions.filter((s) => isSubscriptionActive(s));
  },

  isLoggedIn: (state) => state.loggedIn,

  isConfirmed: (state) => state.userProfile.confirmed,

  // MPC-2515 openbay plus trumps lyft
  isLyftUser: (state) => state.userProfile.isLyftDriver && !state.subscriptions.length,

  getAvailableRewards: (state) => state.userProfile.rewards,

  getUsedRewards: (state) => state.userProfile.usedRewards,

  getVehicles: (state) => state.ownedVehicles,

  hasInitialSubscription: (state) => state.hasInitialSubscription,

  mustPurchaseSubscription: (state) =>
    state.partners.some((s) => s.initialSubRequired) && !state.hasInitialSubscription,

  paywalledProgramAndPlan: (state) => {
    const partner = state.partners.find((s) => s.initialSubRequired);
    if (!partner) return {};

    const program = state.programs.find((p) => p.partnerId === partner.id);
    if (!program) return {};

    const plan = program.programPlans[0];
    if (!plan) return {};

    return {
      programName: program.name,
      planName: plan.name
    };
  },

  getVehicle: (state) => (id: number) => {
    return state.ownedVehicles.find((ownedVehicle) => {
      return ownedVehicle.id == id;
    });
  },

  getNotificationMethod: (state) => {
    if (
      state.userProfile.contactViaTextMessage === true &&
      state.userProfile.phoneNumber !== '' &&
      state.userProfile.email !== ''
    ) {
      return 'both';
    } else if (
      state.userProfile.contactViaTextMessage === false &&
      state.userProfile.email !== ''
    ) {
      return 'email';
    } else if (
      state.userProfile.contactViaTextMessage === true &&
      state.userProfile.phoneNumber !== ''
    ) {
      return 'phone';
    } else {
      return 'none'; // This can happen if they are a Facebook user who has rejected sharing their e-mail. There is no way to contact them outside of messaging.
    }
  },

  getZipcode: (state) => state.location.zipcode,

  getLocation: (state) => state.location,

  getSubscriptionOptions: (state) => state.subscriptionOptions
};

const actions: ActionTree<UserState, RootState> = {
  setUserProfile({ commit }, userProfile: User): void {
    commit('setUserProfile', userProfile);
  },

  resetUnreadCount({ commit }): void {
    commit('resetUnreadCount');
  },

  setUnreadCount({ commit }, unreadCount: number): void {
    commit('setUnreadCount', unreadCount);
  },

  createUser({ commit }, userRequest: CreateUserRequest): Promise<string> {
    return authService
      .createAccount(userRequest)
      .then((userProfile: User) => {
        const openbayId = userProfile.openbayId;
        if (openbayId) {
          segmentServices.aliasUser(openbayId);
        }
        segmentServices.trackGeneral('Registration Succeeded', undefined);
        commit('setUserProfile', userProfile);
        return 'Success';
      })
      .catch((error: AxiosError) => {
        segmentServices.trackGeneral('Registration Failed', error);
        throw error;
      });
  },

  completePartialUser({ commit }, partialUser: CompletePartialUserRequest): Promise<string> {
    return authService
      .completePartialUser(partialUser)
      .then((userProfile: User) => {
        const { openbayId } = userProfile;
        if (openbayId) segmentServices.aliasUser(openbayId);
        segmentServices.trackGeneral('Complete Partial User Succeeded');
        commit('setUserProfile', userProfile);
        return 'Success';
      })
      .catch((error: AxiosError) => {
        segmentServices.trackGeneral('Complete Partial User Failed', error);
        throw error;
      });
  },

  createOwnedVehicle({ commit }, vehicleRequest: VehicleRequest): Promise<string> {
    return userService
      .addOwnedVehicle(vehicleRequest)
      .then((ownedVehicle) => {
        commit('addOwnedVehicle', ownedVehicle);
        return 'Successfully added vehicle.';
      })
      .catch((error) => {
        throw error;
      });
  },

  setOffersOnServiceRequest({ commit }, payload): void {
    commit('setOffersOnServiceRequest', payload);
  },

  updateOwnedVehicle({ commit }, ownedVehicle: Partial<OwnedVehicle>) {
    return ownedVehicleService
      .updateOwnedVehicle(ownedVehicle)
      .then((ownedVehicle) => {
        commit('updateOwnedVehicle', ownedVehicle);
      })
      .catch((error) => {
        throw error;
      });
  },

  updateProfile({ commit }, userProfile): Promise<User> {
    return userService
      .updateUserProfile(userProfile)
      .then((user) => {
        commit('setUserProfile', user);
        return user;
      })
      .catch((error) => {
        commit('resetUser');
        throw error;
      });
  },

  fetchProfile({ commit }): Promise<User> {
    return userService
      .retrieveUserProfile()
      .then((response: User) => {
        commit('setUserProfile', response);
        return response;
      })
      .catch((error: string) => {
        commit('resetUser');
        throw error;
      });
  },

  fetchSubscriptions({ commit }): Promise<Subscription[]> {
    return new SubscriptionService().getVOSubscriptions().then((subscriptions) => {
      commit('setSubscriptions', subscriptions);
      return subscriptions;
    });
  },

  fetchPrograms({ commit }): Promise<Program[]> {
    return new CoverageService().getMyPrograms().then((programs) => {
      commit('setPrograms', programs);
      return programs;
    });
  },

  fetchPartners({ commit }): Promise<Partner[]> {
    return new PartnerService().getMyPartners().then((partners) => {
      commit('setPartners', partners);
      return partners;
    });
  },

  fetchPartnerOffers({ commit }, partnerId: number): Promise<PartnerOffer[]> {
    return new PartnerService().myOffers(partnerId).then((partnerOffers) => {
      const offers = partnerOffers.data || [];
      commit('setPartnerOffers', offers);
      return offers;
    });
  },

  fetchSubscriptionOptions({ commit }, partnerId: number): Promise<SubscriptionOption[]> {
    return new SubscriptionService().getSubscriptionOptions(undefined, partnerId).then((options) => {
      commit('setSubscriptionOptions', options);
      return options;
    });
  },

  fetchPartialVehicles({ commit }): Promise<string> {
    return new SubscriptionService()
      .getPartialVehicles()
      .then((partialVehicles) => {
        commit('setPartialVehicles', partialVehicles);
        return 'Successfully fetched partial vehicles';
      })
      .catch((error: AxiosError) => {
        throw error.message;
      });
  },
  fetchVehicles({ commit }): Promise<string> {
    return userService
      .fetchVehicles()
      .then((ownedVehiclesResponse) => {
        commit('setOwnedVehicles', ownedVehiclesResponse);
        return 'Successfully fetched owned vehicles';
      })
      .catch((error: AxiosError) => {
        throw error.message;
      });
  },

  logout({ commit, state }, redirectToHome?: boolean) {
    // MPC-5583: Everything Breaks users go to the landing page on logout.
    const ebProgram = state.programs.find((p) => p.name === 'everything-breaks');

    localforage.clear();
    commit('resetUser');

    if (redirectToHome && ebProgram && ebProgram.programPlans?.length) {
      router.push({
        name: 'subscription',
        params: { programName: ebProgram.name, planName: ebProgram.programPlans[0].name }
      });
    } else if (redirectToHome) {
      router.push({ path: '/' });
    }
  },

  setZipcode({ commit }, zipcode: string): void {
    commit('setZipcode', zipcode);
  },

  fetchLocation({ commit }, zipcode: string): Promise<ZipcodeLocation> {
    return zipcodeService
      .searchByZipcode(zipcode)
      .then((locations) => {
        commit('setLocation', locations[0]);
        return locations[0];
      })
      .catch((error) => {
        throw error;
      });
  },

  fetchHasInitialSubscription({ commit }): Promise<boolean> {
    return new SubscriptionService().getSubscriptionsCheck().then(({ result }) => {
      commit('setHasInitialSubscription', result);
      return result;
    });
  },

  async fetchRequiredResources({ dispatch }): Promise<any[]> {
    const [subscriptions, programs, partners, hasInitialSubscription] = await Promise.all([
      dispatch('fetchSubscriptions'),
      dispatch('fetchPrograms'),
      dispatch('fetchPartners'),
      dispatch('fetchHasInitialSubscription')
    ]);
    const partnerId = selectProgram(programs)?.partnerId;
    const [offers, subOptions] = await Promise.all([
      dispatch('fetchPartnerOffers', partnerId),
      dispatch('fetchSubscriptionOptions', partnerId)
    ]);
    return [subscriptions, programs, offers, partners, hasInitialSubscription, subOptions];
  },

  setLocation({ commit }, location: ZipcodeLocation): void {
    commit('setLocation', location);
  },
};

const mutations: MutationTree<UserState> = {
  resetUnreadCount(state) {
    state.userProfile.unreadCount = 0;
  },

  setHasServiceRequest(state, value: boolean) {
    state.hasServiceRequests = value;
  },

  setUnreadCount(state, unreadCount) {
    state.userProfile.unreadCount = unreadCount;
  },

  addOwnedVehicle(state, ownedVehicle: OwnedVehicle) {
    state.ownedVehicles.push(ownedVehicle);
  },

  setUserProfile(state, userProfile: User) {
    state.userProfile = userProfile;
    state.loggedIn = true;
    localforage.setItem('loggedIn', 'true');
  },

  setOwnedVehicles(state, ownedVehicles: OwnedVehicle[]) {
    state.ownedVehicles = ownedVehicles;
  },

  setHasInitialSubscription(state, value: boolean) {
    state.hasInitialSubscription = value;
  },

  setPartnerOffers(state, offers: PartnerOffer[]) {
    state.partnerOffers = offers;
  },

  setPartialVehicles(state, partialVehicles: PartialVehicle[]) {
    state.partialVehicles = partialVehicles;
  },

  resetUser(state) {
    localforage.clear();
    Object.assign(state, initialState());
  },

  setSubscriptions(state, subscriptions: Subscription[]) {
    gtagService.setPartner(subscriptions);
    state.subscriptionsLoaded = true;
    state.subscriptions = subscriptions;
  },

  setPrograms(state, programs: Program[]) {
    state.programs = programs;
  },

  setPartners(state, partners: Partner[]) {
    state.partners = partners;
  },

  setSubscriptionsLoaded(state, value: boolean) {
    state.subscriptionsLoaded = value;
  },

  setActiveSubscription(state, subscription: Subscription) {
    state.activeSubscription = subscription;
  },

  setZipcode(state, zipcode: string) {
    state.location.zipcode = zipcode;
  },

  setLocation(state, location: ZipcodeLocation) {
    state.location = location;
  },

  setSubscriptionOptions(state, options: SubscriptionOption[]) {
    state.subscriptionOptions = options;
  },

  updateOwnedVehicle(state, ownedVehicle: OwnedVehicle) {
    const vehicleIndex = state.ownedVehicles.findIndex((vehicle) => vehicle.id === ownedVehicle.id);

    if (vehicleIndex > -1) {
      Vue.set(state.ownedVehicles, vehicleIndex, ownedVehicle);
    } else {
      state.ownedVehicles.push(ownedVehicle);
    }
  },
};

const UserStore: Module<UserState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default UserStore;
