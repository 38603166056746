


















import Vue from 'vue';
import { BFormSelect, BFormSelectOption } from 'bootstrap-vue';
import countryList from 'country-list';

export default Vue.extend({
  name: 'CountrySelect',

  components: {
    BFormSelect,
    BFormSelectOption
  },

  props: {
    options: {
      type: Object,
      default: () => ({})
    },

    required: {
      type: Boolean,
      default: false
    },

    default: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      countries: [] as { code: string; name: string }[],
      selected: ''
    };
  },

  mounted(): void {
    this.countries = countryList.getData();
    if (this.default) this.selected = this.default;
  }
});
