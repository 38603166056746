








































































import { BTab, BTabs } from 'bootstrap-vue';
import { uniq } from 'underscore';

import { gtagService } from '@/services/gtagService';
import { FormattedSubscriptionOption } from '@/types/FormattedSubscriptionOption';
import { SubscriptionOption } from '@/types/microservices/SubscriptionOption';
import { monetize } from '@/utilities';

import SubscriptionOptionsComparison from './SubscriptionOptionsComparison.vue';
import SubscriptionOptionsFaq from './SubscriptionOptionsFaq.vue';

const FREE_PLAN: FormattedSubscriptionOption = {
  label: 'Free',
  subHeading: 'Current Plan',
  amount: 0,
  description:
    'Find, get upfront pricing, and book automotive service. Discounts on services are shown, but not applied to booked services.',
  borderClass: 'border-red',
  formattedPrice: '0'
};

import CombinedComponent, { configMixin, userMixin } from '@/mixins';

export default CombinedComponent(userMixin, configMixin).extend({
  name: 'SubscriptionOptions',

  components: {
    BTabs,
    BTab,
    SubscriptionOptionsComparison,
    SubscriptionOptionsFaq
  },

  props: {
    modal: {
      type: Boolean,
      default: false
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    showFreePlanButton: {
      type: Boolean,
      default: false
    },
    hideFreePlan: {
      type: Boolean,
      default: false
    },
    fromPage: {
      type: String,
      required: false,
      default: 'banner'
    }
  },

  data() {
    return {
      tab: 0,
      loading: false,
      showComparison: true
    };
  },

  computed: {
    faqRef(): InstanceType<typeof SubscriptionOptionsFaq> {
      return this.$refs['faqs'] as InstanceType<typeof SubscriptionOptionsFaq>;
    },

    optionColumnClasses(): string[] {
      // We might need to tweak these later
      let classes = ['option-col', 'col mt-2'];
      return classes;
    },

    partnerId(): number {
      const [program] = this.$store.getters['user/getPrograms'];
      return program.partnerId || 0;
    },

    subscriptionOptions(): FormattedSubscriptionOption[] {
      const options = this.formatSubscriptionOptions(this.$store.getters['user/getSubscriptionOptions']);
      if (!this.hideFreePlan && options.length < 4) options.unshift(FREE_PLAN);
      return options.sort((opt1, opt2) => (opt1.amount || 0) - (opt2.amount || 0));
    }
  },

  async mounted() {
    if (!this.hideFreePlan && !this.subscriptionOptions.find((o) => o.amount === 0))
      this.subscriptionOptions.push(FREE_PLAN);
    gtagService.event('subscription_plans_viewed', {
      from: this.fromPage
    });
    window.addEventListener('resize', this.windowResized);
    this.showComparison = window.innerWidth >= 768 || !this.modal;
  },

  destroyed() {
    window.removeEventListener('resize', this.windowResized);
  },

  methods: {
    windowResized() {
      if (!this.modal) return;

      this.showComparison = window.innerWidth >= 768;
    },

    getFrequencyAbbreviation(frequency: string) {
      return (
        {
          Yearly: '12 mo',
          Monthly: '1 mo',
          Quarterly: '3 mo',
          fifteenMo: '15 mo'
        }[frequency] || ''
      );
    },

    frequencyToNoun(frequency: string) {
      return (
        {
          Yearly: 'year',
          Monthly: 'month',
          Quarterly: 'quarter'
        }[frequency] || ''
      );
    },

    formatSubscriptionOptions(options: SubscriptionOption[]): FormattedSubscriptionOption[] {
      return uniq(options, 'id').map(this.formatSubscriptionOption);
    },

    getSubheading(frequency: string) {
      return (
        {
          Free: 'Free Plan',
          Yearly: '12 Month Plan',
          Monthly: '1 Month Plan',
          Quarterly: '3 Month Plan'
        }[frequency] || ''
      );
    },

    getFrequencyLabel(frequency: string) {
      return (
        {
          Yearly: '12 months',
          Monthly: '1 month',
          Quarterly: '3 months'
        }[frequency] || ''
      );
    },

    /* For quarterly plans we display how much it would be per month but billing is every 3 months  */
    priceForDisplay(option: FormattedSubscriptionOption) {
      if (option.frequency === 'Quarterly' && option.amount)
        return monetize(option.amount / 3, 'cents').replace('$', '');

      return option.formattedPrice;
    },

    frequencyForDisplay(option: FormattedSubscriptionOption) {
      if (option.frequency === 'Quarterly' && option.amount) return 'mo';

      return this.getFrequencyAbbreviation(option.frequency!);
    },
    /* ============================================================================================== */

    formatSubscriptionOption(option: SubscriptionOption, index: number = 0): FormattedSubscriptionOption {
      const borderColors = ['border-red', 'border-blue', 'border-orange'];
      const formatted = {
        borderClass: borderColors[(index + 1) % borderColors.length],
        subHeading: this.getSubheading(option.frequency),
        formattedPrice: monetize(option.amount, 'cents').replace('$', '')
      };
      return { ...formatted, ...option };
    },

    customBadgeClasses(option: FormattedSubscriptionOption, index: number): string[] {
      const classes = ['bg-success'];
      const previousOption: FormattedSubscriptionOption | undefined = this.subscriptionOptions[index - 1];
      if (option.frequency === 'Yearly' && previousOption?.frequency === 'Yearly') classes.push('badge-border-left');
      return classes;
    },

    captialize(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    showOpenbayRecommended(opt: FormattedSubscriptionOption): boolean {
      if (!opt.frequency) return false;
      return ['Yearly', 'fifteenMo'].includes(opt.frequency);
    }
  }
});
