import { fetchConfig } from '@/init/app-config';
import { logger } from '@/services/loggerService';

let stripe: stripe.Stripe;

export default {
  stripeInstance(): stripe.Stripe {
    if (typeof stripe !== 'undefined') {
      return stripe;
    } else {
      stripe = Stripe(fetchConfig().get('VUE_APP_STRIPE_PUBLIC_KEY') || '');
      return stripe;
    }
  },
  initStripe(): stripe.elements.Element {
    const elements = this.stripeInstance().elements();
    const card = elements.create('card', {
      hidePostalCode: true
    });

    return card;
  },
  createStripeToken(card: stripe.elements.Element, additionalOptions: stripe.TokenOptions): Promise<stripe.Token> {
    //Fill this in
    return this.stripeInstance()
      .createToken(card, additionalOptions)
      .then((result: stripe.TokenResponse) => {
        if (result.error) {
          logger.error(result.error);
          throw result.error.message;
        }
        return Promise.resolve(result.token!);
      });
  }
};
