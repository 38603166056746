




































import Vue, { PropType } from 'vue';
import { VBPopover, BButton } from 'bootstrap-vue';
import { debounce, flatten, omit } from 'underscore';
import { isOneOf, isEmail, isPhoneNumber } from '@/concerns/validator';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

const VALIDATION_DEBOUNCE_TIME = 500;

type InputType = 'text' | 'email' | 'phone' | 'password' | 'number' | 'tel';
const inputTypes: InputType[] = ['text', 'email', 'phone', 'password', 'number', 'tel']; // eslint-disable-line

type InputSize = 'lg' | 'md' | 'sm';
const inputSizes: InputSize[] = ['lg', 'md', 'sm'];

Vue.directive('b-popover', VBPopover);

const capitalizeFirst = (str: string): string => str.replace(/^\s*([a-z])/, (char) => char.toUpperCase());

library.add(faEye, faEyeSlash);

export default Vue.extend({
  name: 'BaseInput',

  components: {
    'font-awesome-icon': FontAwesomeIcon,
    'b-button': BButton
  },

  inheritAttrs: false,

  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    value: {
      type: [String],
      default: ''
    },

    type: {
      type: String as PropType<InputType>,
      default: 'text',
      validator: isOneOf(inputTypes)
    },

    name: {
      type: String,
      default: ''
    },

    mask: {
      type: String,
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    success: {
      type: Boolean,
      default: false
    },

    successWhenValid: {
      type: Boolean,
      default: false
    },

    required: {
      type: Boolean,
      default: false
    },

    size: {
      type: String as PropType<InputSize>,
      default: 'lg',
      validator: isOneOf(inputSizes)
    },

    multiline: {
      type: Boolean,
      default: false
    },

    rows: {
      type: Number,
      default: 3
    },

    validators: {
      type: Array as () => ((value: string|number) => string|string[]|void|null|false)[], // eslint-disable-line
      default: () => []
    },

    isValid: {
      type: Boolean,
      default: false
    },

    popoverOptions: {
      type: Object,
      default: () => ({})
    },

    passwordToggle: {
      type: Boolean,
      default: false
    },

    clearInput: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      debouncedValidate: debounce(() => {}, VALIDATION_DEBOUNCE_TIME),
      text: this.value || '',
      errors: [] as string[],
      validated: false,
      fieldType: this.type || 'text',
      passwordLength: 0,
      containsMinChars: false,
      containsNumber: false,
      containsUppercase: false,
      containsSpecialChar: false,
      validPattern: false
    };
  },

  computed: {
    hasErrors(): boolean {
      return this.errors.length > 0;
    },

    htmlType(): string {
      const aliases = {
        number: 'number',
        email: 'email',
        phone: 'tel',
        tel: 'tel',
        password: 'password'
      };
      return aliases[this.fieldType as keyof typeof aliases] || 'text';
    },

    humanName(): string {
      const aliases = {
        email: 'email address',
        tel: 'phone number',
        phone: 'phone number'
      };

      const normalize = (str: string) =>
        str.replace(/[_-]/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase(); // eslint-disable-line

      return this.name ? normalize(this.name) : aliases[this.type as keyof typeof aliases] || this.type;
    },

    inputListeners(): Vue['$listeners'] {
      return omit(this.$listeners, 'input');
    },

    validForType(): (value: string | number) => boolean {
      switch (this.htmlType) {
        case 'email':
          return isEmail({ allowBlank: !this.required });
        case 'tel':
          return isPhoneNumber({ allowBlank: !this.required });
        default:
          return (_value) => true;
      }
    },

    showSuccess(): boolean {
      if (this.success) return true;
      if (this.successWhenValid && this.validated && !!`${this.value}`.length) return true;
      return false;
    },

    popover(): any {
      if (!this.popoverOptions) return {};
      return {
        html: true,
        content: this.popoverOptions.content,
        placement: 'right',
        trigger: 'hover'
      };
    }
  },
  watch: {
    text(newVal: string | number, oldVal: string | number): void {
      if (newVal === oldVal) return;
      this.$emit('input', newVal);
      this.errors = [];
      this.validated = false;
      this.debouncedValidate();
    },

    clearInput(newVal: any, _oldVal: any) {
      if (newVal) {
        this.text = '';
      }
    }
  },

  created() {
    this.debouncedValidate = debounce(this.validate, VALIDATION_DEBOUNCE_TIME); // eslint-disable-line
  },

  mounted(): void {
    if (this.text) this.validate();
    if (!this.text && !this.required) this.$emit('update:isValid', true);
  },

  methods: {
    validate(): void {
      const validators = [this.typeValidator, ...this.validators];
      const results = validators.map((validator) => validator(this.text));
      this.errors = flatten(results).filter(result => typeof result === 'string') as string[]; // eslint-disable-line
      if (this.errors.length === 0) {
        this.$emit('valid');
        this.$emit('update:isValid', true);
        this.validated = true;
      } else {
        this.$emit('error', this.errors);
        this.$emit('update:isValid', false);
      }
    },

    typeValidator(value: string | number): string | void {
      const requirementFailure = !value && this.required;
      if (!requirementFailure && this.validForType(value)) return;
      return `${capitalizeFirst(this.humanName)}`;
    },

    toggleFieldType(): void {
      if (['text', 'password'].includes(this.fieldType)) {
        this.fieldType = this.fieldType === 'password' ? 'text' : 'password';
      }
    }
  }
});
