/* eslint-disable no-param-reassign */
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import { RootState, SettingsState, UiSettings } from '../types';
import featureService from '@/services/featureService';

const initialState = (): SettingsState => ({
  ui: {
    showSidebar: false
  },
  features: {}
});

const state = initialState();

const getters: GetterTree<SettingsState, RootState> = {
  getUiSettings: (state) => state.ui,
  getFeatures: (state) => state.features
};

const actions: ActionTree<SettingsState, RootState> = {
  setUiSettings({ commit }: any, uiSettings: UiSettings) {
    commit('setUiSettings', uiSettings);
  },
  fetchFeatures({ commit }: any) {
    featureService.getFeatures().then((features) => {
      commit('setFeatures', features);
    });
  },
  reset({ commit }: any) {
    commit('reset');
  }
};

const mutations: MutationTree<SettingsState> = {
  setUiSettings(state, settings: UiSettings) {
    state.ui = settings;
  },
  setFeatures(state, features: Record<string, boolean>) {
    state.features = features;
  },
  reset(state) {
    Object.assign(state, initialState());
  }
};

const SettingsStore: Module<SettingsState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default SettingsStore;
