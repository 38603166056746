











































import { UiSettings } from '@/store/types';
import { OwnedVehicle } from '@/types/resources/OwnedVehicles';
import { AppointmentSlot } from '@/types/ServiceRequest';

import CombinedComponent, { userMixin } from '@/mixins';

export default CombinedComponent(userMixin).extend({
  name: 'AppointmentNavigation',

  computed: {
    currentStepLabel(): string {
      return this.currentStep;
    },

    currentStep(): string {
      return this.$route.meta?.step;
    },

    chevronDirection(): 'up' | 'down' {
      return this.uiSettings.showSidebar === true ? 'up' : 'down';
    },

    uiSettings(): UiSettings {
      return this.$store.getters['settings/getUiSettings'];
    },

    ownedVehicle(): OwnedVehicle {
      return this.$store.getters['appointment/getOwnedVehicle'];
    },

    appointmentSlot(): AppointmentSlot {
      return this.$store.getters['appointment/getAppointmentSlot'];
    },

    notes(): AppointmentSlot {
      return this.$store.getters['appointment/getNotes'];
    },

    accountIsConfirmed(): boolean {
      return this.$store.getters['user/isConfirmed'];
    },

    didJustRegister(): boolean {
      return this.$store.getters['appointment/didJustRegister'];
    },

    carWashAppointment(): boolean {
      return this.$store.getters['appointment/getCarWash'];
    }
  },

  destroyed(): void {
    this.$store.dispatch('settings/setUiSettings', {
      showSidebar: false
    });
  },

  methods: {
    toggleSidebar(): void {
      const showSidebar = !this.uiSettings.showSidebar;

      this.$store.dispatch('settings/setUiSettings', {
        showSidebar: showSidebar
      });
    },

    isCompleted(step: string): boolean {
      switch (step) {
        case 'vehicle':
        case 'schedule':
          return !!this.ownedVehicle;
        case 'notes':
          return !!this.appointmentSlot && !['vehicle', 'schedule'].includes(this.currentStep);
        case 'register':
          return !!this.notes || (this.isLoggedIn && this.accountIsConfirmed);
        case 'confirm':
          return !!this.isLoggedIn;
        default:
          return false;
      }
    }
  }
});
