










import { PartnerConfig } from '../store/types';
import CombinedComponent, { userMixin } from '../mixins';
import { selectProgram } from '../utilities';

export default CombinedComponent(userMixin).extend({
  name: 'GlobalFooter',
  computed: {
    copyrightNotice(): string {
      if (this.partnerConfig?.strings?.copyrightNotice)
        return this.partnerConfig.strings?.copyrightNotice;
      else
        return '';
    },

    programNameSlug(): string {
      return this.$route.params.programName?.toLowerCase() || selectProgram(this.programs)?.name || '';
    },

    partnerConfig(): PartnerConfig {
      return this.$store.getters['config/getPartnerConfig'](this.programNameSlug);
    }
  }
});
