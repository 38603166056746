





























































































































import { BModal } from 'bootstrap-vue';

import SubscriptionPaymentForm from '@/components/Payment/SubscriptionPaymentForm.vue';
import SubscriptionOptions from '@/components/Subscription/SubscriptionOptions.vue';
import { gtagService } from '@/services/gtagService';
import SubscriptionService from '@/services/microservices/subscriptionService';
import stripeService from '@/services/stripeService';
import { FormattedSubscriptionOption } from '@/types/FormattedSubscriptionOption';
import { CreateSubscriptionRequest } from '@/types/microservices/CreateSubscriptionRequest';
import { PaymentMethod } from '@/types/microservices/PaymentMethod';
import { SubscriptionOption } from '@/types/microservices/SubscriptionOption';
import { monetize } from '@/utilities';

const FREE_PLAN: FormattedSubscriptionOption = {
  label: 'Free',
  subHeading: 'Current Plan',
  amount: 0,
  description:
    'Find, get upfront pricing, and book automotive service. Discounts on services are shown, but not applied to booked services.',
  borderClass: 'border-red',
  formattedPrice: '0'
};

import CombinedComponent, { configMixin, userMixin } from '@/mixins';

export default CombinedComponent(userMixin, configMixin).extend({
  name: 'SubscriptionOptionsModal',

  components: {
    BModal,
    SubscriptionPaymentForm,
    SubscriptionOptions
  },

  props: {
    hidePlans: {
      type: Boolean,
      default: false
    },
    fromPage: {
      type: String,
      required: false,
      default: 'banner'
    }
  },

  data() {
    return {
      payment: false,
      canSubmit: false,
      tab: 0,
      loading: false,
      error: '',
      subscribedOptionId: 0,
      selectedCard: {} as PaymentMethod,
      selectedOption: {} as FormattedSubscriptionOption,
      subscriptionOptions: [FREE_PLAN],
      processorSubscriptionId: '',
      subscriptionSuccessful: false,
      isUpdatingPaymentInfo: false,
      isRenewingSubscription: false,
      tosAccepted: false,
      showComparison: true,
      modalFromPage: ''
    };
  },

  computed: {
    modalRef(): BModal {
      return this.$refs['joinPlusModal'] as BModal;
    },

    modalTitle(): string {
      if (this.isUpdatingPaymentInfo) return 'Update Payment Method';

      if (this.payment && this.selectedOption) return `Join ${this.labels.openbayPlus} ${this.selectedOption.label}`;

      return `Join ${this.labels.openbayPlus}`;
    },

    isOfferRoute(): boolean {
      return this.$route.path.includes('offer');
    },

    isUsingExistingPayment(): boolean {
      return !!(this.selectedCard?.customerId && this.selectedCard?.paymentToken);
    },

    zipcode(): string | undefined {
      if (typeof this.$route.query.zipcode === 'string') return this.$route.query.zipcode;
      return this.$store.getters['onrampCart/getZipcode'];
    },

    paymentCopy(): string {
      return `${this.captialize(
        this.labels.articlePlus
      )} paid plan gives subscribers access to discounted prices, the final discounted price of the service will be paid directly to the shop after service is completed.`;
    },

    partnerId(): number {
      return this.$store.getters['user/getPrograms'][0]?.partnerId || 0;
    },

    optionsComponent(): InstanceType<typeof SubscriptionOptions> | undefined {
      return this.$refs['subOptions'] as InstanceType<typeof SubscriptionOptions>;
    }
  },

  mounted() {
    this.modalFromPage = this.fromPage;
  },

  methods: {
    async showModal(
      subscriptionOption?: SubscriptionOption,
      processorSubscriptionId?: string,
      paymentStep: 'update' | 'renew' | undefined = undefined
    ) {
      this.subscriptionSuccessful = false;
      this.isUpdatingPaymentInfo = false;
      this.isRenewingSubscription = false;
      this.payment = false;
      this.loading = false;
      this.canSubmit = false;
      this.tosAccepted = false;
      this.error = '';
      this.selectedOption = {} as FormattedSubscriptionOption;
      this.subscribedOptionId = 0;
      this.processorSubscriptionId = '';

      // If we're already subscribed jump to payment step.
      // This happens when a user needs to update their payment info
      // or renew their subscription.
      if (subscriptionOption && processorSubscriptionId) {
        this.subscribedOptionId = subscriptionOption.id;
        this.processorSubscriptionId = processorSubscriptionId;

        if (paymentStep === 'update') {
          this.isUpdatingPaymentInfo = true;
        } else if (paymentStep === 'renew') {
          this.isRenewingSubscription = true;
        }

        this.goToPayment(this.formatSubscriptionOption(subscriptionOption));
      } else if (subscriptionOption) {
        this.goToPayment(this.formatSubscriptionOption(subscriptionOption));
      }

      this.modalRef.show();

      if (!this.hidePlans) {
        gtagService.event('subscription_plans_viewed', {
          from: this.modalFromPage
        });
      }
    },

    hideModal() {
      this.canSubmit = false;
      this.modalRef.hide();
    },

    formValidityChanged(isValid: boolean) {
      this.canSubmit = isValid;
    },

    goToPayment(option: FormattedSubscriptionOption) {
      this.selectedOption = option;
      this.payment = true;
      gtagService.event('subscription_payment_form_viewed', {
        from: this.modalFromPage
      });
    },

    cardSelected(card: PaymentMethod) {
      this.selectedCard = card;
      this.canSubmit = typeof card?.paymentToken !== 'undefined';
    },

    async confirm() {
      if (!(this.selectedOption.id && this.selectedOption.programPlanId)) return; // sanity check

      this.loading = true;

      const subService = new SubscriptionService();
      const paymentForm = this.$refs.paymentForm as InstanceType<typeof SubscriptionPaymentForm>;

      try {
        const subOption = this.subscribedOptionId || this.selectedOption.id;
        const payload: CreateSubscriptionRequest = {
          programPlanId: this.selectedOption.programPlanId,
          subscriptionOptionId: subOption,
          processor: 'stripe'
        };

        if (this.isUsingExistingPayment && this.isRailsPaymentToken(this.selectedCard?.paymentToken)) {
          // if we have an existing card from rails we need to create a setupIntent
          // in order to get a usable payment method.
          const setupResult = await subService.setupIntent(
            this.selectedCard?.customerId,
            this.selectedCard?.paymentToken,
            this.partnerId
          );
          const setupIntent = await this.confirmExistingPaymentMethod(
            setupResult.secret,
            this.selectedCard?.paymentToken
          );
          if (setupIntent.setupIntent?.status != 'succeeded')
            throw setupIntent.error?.message || 'Hmm, something went wrong. Sorry about that.';

          payload.customerId = this.selectedCard?.customerId;
          payload.paymentToken = setupIntent.setupIntent?.payment_method as string;
        } else if (!this.isUsingExistingPayment) {
          // new card, generate a new payment method
          const intentResult = await subService.setupIntent(undefined, undefined, this.partnerId);
          const setupResult = await paymentForm.confirmSetup(intentResult.secret);

          if (setupResult.setupIntent?.status != 'succeeded')
            throw setupResult.error?.message || 'Hmm, something went wrong. Sorry about that.';

          payload.customerId = intentResult.customerId;
          payload.paymentToken = setupResult.setupIntent?.payment_method as string;
        } else {
          // we have an existing payment method we can use, no need to create a setupIntent
          payload.customerId = this.selectedCard?.customerId;
          payload.paymentToken = this.selectedCard?.paymentToken;
        }

        if (this.processorSubscriptionId) payload.processorSubscriptionId = this.processorSubscriptionId;

        if (this.zipcode) payload.zipCode = this.zipcode;

        if (this.partnerId) payload.partnerId = this.partnerId;

        if (this.isUpdatingPaymentInfo) {
          await subService.updatePaymentMethod(this.processorSubscriptionId, payload.paymentToken, this.partnerId);
        } else {
          await subService.subscribeVOToPlanWithOption(payload);
          gtagService.event('subscription_purchased', {
            subscription_option_id: payload.subscriptionOptionId,
            program_plan_id: payload.programPlanId,
            from: this.modalFromPage
          });
        }

        this.subscribedOptionId = subOption;
        this.subscriptionSuccess();
      } catch (error) {
        this.loading = false;
        this.error = error as string;
      }
    },

    subscriptionSuccess(): void {
      // payment was successful, wait a second for the webhook to activate
      // the subscription then refresh the subs.

      const oneSec = () => new Promise<void>((res) => setTimeout(res, this.isRenewingSubscription ? 1000 : 0));

      oneSec()
        .then(() => this.$store.dispatch('user/fetchRequiredResources'))
        .then(() => this.$emit('user-subscribed', this.isUpdatingPaymentInfo))
        .then(() => this.subscriptionsFetched());
    },

    subscriptionsFetched(): void {
      this.subscriptionSuccessful = true;
      this.payment = false;
    },

    isRailsPaymentToken(paymentToken: string) {
      return paymentToken.startsWith('card_');
    },

    async confirmExistingPaymentMethod(setupIntent: string, paymentToken: string): Promise<stripe.SetupIntentResponse> {
      const result = await stripeService.stripeInstance().confirmCardSetup(setupIntent, {
        payment_method: paymentToken
      });
      return result;
    },

    getSubheading(frequency: string) {
      return (
        {
          Free: 'Free Plan',
          Yearly: '12 Month Plan',
          Monthly: '1 Month Plan',
          Quarterly: '3 Month Plan'
        }[frequency] || ''
      );
    },

    formatSubscriptionOption(option: SubscriptionOption, index: number = 0): FormattedSubscriptionOption {
      const borderColors = ['border-red', 'border-blue', 'border-orange'];
      const formatted = {
        borderClass: borderColors[(index + 1) % borderColors.length],
        subHeading: this.getSubheading(option.frequency),
        formattedPrice: monetize(option.amount, 'cents').replace('$', '')
      };
      return { ...formatted, ...option };
    },

    cancelClicked() {
      if (this.isUpdatingPaymentInfo || this.isRenewingSubscription || this.hidePlans) {
        this.hideModal();
      } else {
        this.payment = false;
      }
    },

    captialize(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    goBack(): void {
      this.payment = false;
      if (this.hidePlans) this.modalRef.hide();
    },

    setFromPage(fromPage: string) {
      this.modalFromPage = fromPage;
    }
  }
});
