import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import router from '@/router';
import store from '@/store/store';
import { camelKeys, isNullish, snakeKeys } from '@/utilities';

import { fetchConfig } from './init/app-config';

const appConfig = fetchConfig();
const serverEnvironment = appConfig.get('VUE_APP_ENVIRONMENT');
const apiBaseUrl = appConfig.get('VUE_APP_API_BASE_URL');
const aiAssistantBaseUrl = appConfig.get('VUE_APP_ONRAMP_AI_URL');
const username = appConfig.get('VUE_APP_AUTH_USERNAME');
const password = appConfig.get('VUE_APP_AUTH_PASSWORD');

const environmentHasAuth = serverEnvironment === 'development' || serverEnvironment === 'staging';
const auth = {
  username,
  password
};

// HTTP client config for making requests to the Rails stack using JSON:API specification
const config = {
  baseURL: apiBaseUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json'
  },
  ...(environmentHasAuth && { auth })
} as AxiosRequestConfig;

const httpClient = axios.create(config);

// HTTP client config for making requests to the Onramp AI Assistant
const onrampClientConfig = {
  baseURL: aiAssistantBaseUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  ...(environmentHasAuth && { auth })
} as AxiosRequestConfig;

const aiClient = axios.create(onrampClientConfig);

// Add global request interceptors
httpClient.interceptors.request.use(
  (config) => {
    config.data = snakeKeys(config.data);
    config.withCredentials = true;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/** Adding global response interceptors */
httpClient.interceptors.response.use(
  (response: AxiosResponse) => {
    // If this is a successful response formatted as JsonSpec, process it with some assumptions.
    if (!isNullish(response.data?.data)) {
      response.data = camelKeys(response.data);
    }

    return response;
  },
  (error) => {
    const { response } = error;
    if (!response) return Promise.reject(error);

    if (response.status === 401) {
      store.dispatch('user/logout');
    } else if (response.status === 429) {
      return router.push({ name: '429' }).catch((error: Error) => {
        if (error.name === 'NavigationDuplicated') return { data: {} };
        throw error;
      });
    }

    return Promise.reject(error);
  }
);

export { httpClient, aiClient };
