











































































































import Vue from 'vue';
import segmentServices from '@/services/segmentServices';
import creditCardService from '@/services/creditCardService';
import monthSelect from '@/components/Input/MonthSelect.vue';
import yearSelect from '@/components/Input/YearSelect.vue';
import stateSelect from '@/components/Input/StatesSelect.vue';
import requiredInput from '@/components/Input/RequiredInput.vue';
import { toFileName, isNullish } from '@/utilities';

//Components
import { BAlert } from 'bootstrap-vue';

export default Vue.extend({
  name: 'EditCreditCard',

  components: {
    'month-select': monthSelect,
    'year-select': yearSelect,
    'state-select': stateSelect,
    'required-input': requiredInput,
    BAlert
  },

  props: {
    card: {
      type: Object,
      default: () => {}
    },
    offer: {
      type: Object,
      default: () => {}
    },
    serviceRequest: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      error: '',
      loading: true,
      cardDetails: {
        name: '',
        expMonth: 0,
        expYear: 0,
        addressLine1: '',
        addressLine2: '',
        addressCity: '',
        addressState: '',
        addressZip: ''
      },
      zipCodeValid: false,
      cityValid: false,
      address1Valid: false,
      nameValid: false
    };
  },

  computed: {
    formattedMonth(): string {
      return this.cardDetails.expMonth < 10 ? `0${this.cardDetails.expMonth}` : `${this.cardDetails.expMonth}`;
    },
    formIsValid(): boolean {
      return this.nameValid && this.address1Valid && this.cityValid && this.zipCodeValid && !this.loading;
    }
  },

  created() {
    creditCardService
      .retrieveSavedCard(this.card.id)
      .then((card) => {
        this.loading = false;

        const cardDetails = {
          name: card.stripeCardInfo.name,
          expMonth: card.stripeCardInfo.expMonth,
          expYear: card.stripeCardInfo.expYear,
          addressLine1: card.stripeCardInfo.addressLine1,
          addressLine2: card.stripeCardInfo.addressLine2,
          addressCity: card.stripeCardInfo.addressCity,
          addressState: card.stripeCardInfo.addressState,
          addressZip: card.stripeCardInfo.addressZip
        };
        this.cardDetails = cardDetails;

        this.nameValid = this.cardDetails.name ? this.cardDetails.name.length > 0 : false;
        this.address1Valid = this.cardDetails.addressLine1 ? this.cardDetails.addressLine1.length > 0 : false;
        this.cityValid = this.cardDetails.addressCity ? this.cardDetails.addressCity.length > 0 : false;
        this.zipCodeValid = this.cardDetails.addressZip ? this.cardDetails.addressZip.length >= 5 : false;
      })
      .catch((error) => {
        this.loading = false;
        this.error = error;
      });
  },

  methods: {
    updateCard(): void {
      this.loading = true;
      creditCardService
        .updateSavedCard(this.card.id, this.cardDetails)
        .then((response) => {
          this.loading = false;
          this.$emit('edit-successful', response);
          this.recordSegmentEvent();
        })
        .catch((error: string) => {
          this.loading = false;
          this.error = error;
        });
    },

    recordSegmentEvent(): void {
      if (!isNullish(this.serviceRequest) && !isNullish(this.offer)) {
        segmentServices.trackServiceRequestAndOffer('Payment Method Updated', this.serviceRequest, this.offer);
      } else if (!isNullish(this.serviceRequest) && isNullish(this.offer)) {
        segmentServices.trackServiceRequest('Payment Method Updated', this.serviceRequest);
      } else {
        segmentServices.trackGeneral('Payment Method Updated', undefined);
      }
    },

    expirationMonthChanged(month: string) {
      this.cardDetails.expMonth = Number(month);
    },

    expirationYearChanged(year: string) {
      this.cardDetails.expYear = Number(year);
    },

    stateChanged(state: string) {
      this.cardDetails.addressState = state;
    },

    updateZipcode(zipcode: string) {
      this.cardDetails.addressZip = zipcode;
    },

    updateZipcodeValidity(isValid: boolean) {
      this.zipCodeValid = isValid;
    },

    updateCity(city: string) {
      this.cardDetails.addressCity = city;
    },

    updateCityValidity(isValid: boolean) {
      this.cityValid = isValid;
    },

    updateAddress1(address: string) {
      this.cardDetails.addressLine1 = address;
    },

    updateAddress1Validity(isValid: boolean) {
      this.address1Valid = isValid;
    },

    updateName(name: string) {
      this.cardDetails.name = name;
    },

    updateNameValidity(isValid: boolean) {
      this.nameValid = isValid;
    },

    toFileName: toFileName
  }
});
// 1. Create Token
// 2. Save card to user if needed
// 3. Accept Service Request offer with service request ID and offer ID and Card ID
