export interface JoinPlusBannerConfig {
  bannerColor: string;
  bannerText: string;
  mobileBannerText: string;
  modalTitle?: string;
  modalHtml?: string;
  modalHtmlExpanded?: string;
  joinButtonText?: string;
}

export const PartnerBannerConfigs: Record<string, JoinPlusBannerConfig> = {
  lyft: {
    bannerColor: '#E83C79',
    bannerText: 'Save upwards of 25% on automotive services - click here to learn more.',
    mobileBannerText: 'Save upwards of 25% on automotive services.\nClick here to learn more.',
    modalHtml: `
      <span>
        Start saving on automotive services today with Openbay+, included as a special
        benefit for Lyft drivers.
      </span>
      <br>
      <br>
      <ul>
        <li>Savings up to 25% on services</li>
        <li>No credit card required to enroll</li>
        <li>Expanded coverage areas for service</li>
      </ul>
    `,
    modalHtmlExpanded: `
      <p>
        Unlock access to 1,000s of services for your vehicle. Receive multiple competitive
        estimates from service professionals nearby. Each price estimate arrives with full
        transparency - detail on parts and labor are provided.
      </p>
      <p>
        Book service appointments with a professional that meets your needs; from mobile mechanics to local independent shops, to national automotive service brands.
      </p>
      <p>No credit card required to enroll or book a service appointment.</p>
      <p>Enjoy!</p>
    `,
    modalTitle: 'Openbay+ on us.',
    joinButtonText: 'Enroll now'
  },
  openbayPlus: {
    bannerColor: '#E83C79',
    bannerText: 'Save upwards of 25% on automotive services - click here to learn more.',
    mobileBannerText: 'Save upwards of 25% on automotive services.\nClick here to learn more.'
  }
};
