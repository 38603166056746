




import Vue from 'vue';
import { BFormSelect } from 'bootstrap-vue';

export default Vue.extend({
  name: 'YearSelect',

  components: {
    'b-form-select': BFormSelect
  },

  props: {
    initialYear: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      selected: this.initialYear
    };
  },

  computed: {
    // creates an array of 20 years starting with the current year
    yearArray(): Array<number> {
      const startingYear = new Date().getFullYear();
      var yearsArray = new Array();
      for (var i = startingYear; i < startingYear + 20; i++) {
        yearsArray.push(i);
      }
      return yearsArray;
    }
  },

  methods: {
    yearChanged(year: string) {
      this.$emit('year-changed', year);
    }
  }
});
