import { AxiosError, AxiosResponse } from 'axios';

import { httpClient } from '@/api';
import { handleError, handleSuccess } from '@/services/helpers';
import { CreditCard, EditCreditCard } from '@/types/CreditCard';

export default {
  saveCard(stripeToken: stripe.Token): Promise<any> {
    return httpClient
      .post(`v3/users/credit_cards`, {
        stripeCustomerToken: stripeToken.id
      })
      .then((response: AxiosResponse) => {
        return handleSuccess(response);
      })
      .catch((error: AxiosError) => {
        throw handleError(error);
      });
  },
  retrieveSavedCard(creditCardId: number): Promise<any> {
    return httpClient
      .get(`v3/users/credit_cards/${creditCardId}`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },
  retrieveSavedCards(): Promise<CreditCard[]> {
    return httpClient
      .get(`v3/users/credit_cards`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  },
  deleteSavedCard(cardId: number): Promise<any> {
    return httpClient
      .delete(`v3/users/credit_cards/${cardId}`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw handleError(error);
      });
  },
  updateSavedCard(creditCardId: number, cardDetails: EditCreditCard): Promise<any> {
    return httpClient
      .put(`v3/users/credit_cards/${creditCardId}`, {
        id: creditCardId,
        newCardDetails: cardDetails
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw handleError(error);
      });
  }
};
