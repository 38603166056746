













































































































import { PropType } from 'vue';

//Components
import { BCollapse, BDropdown, BDropdownItem, BNavbar, BNavbarToggle } from 'bootstrap-vue';

import { fetchConfig } from '@/init/app-config';
import { gtagService } from '@/services/gtagService';
import PartnerService from '@/services/microservices/partnerService';
import { PartnerOffer } from '@/types/microservices/PartnerOffer';
import { isNullish, objectIsEmpty } from '@/utilities';

import authService from '../services/authService';
import Logo from './Logo.vue';

import CombinedComponent, { userMixin } from '@/mixins';

export default CombinedComponent(userMixin).extend({
  name: 'Navbar',

  components: {
    BDropdown,
    BDropdownItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    Logo
  },

  props: {
    transitionException: {
      type: Array as PropType<string[]>,
      required: true
    }
  },

  data() {
    return {
      transitionName: 'slide-up',
      adminUrl: fetchConfig().get('adminDashboard'),
      nuAdminUrl: fetchConfig().get('nuAdminUrl'),
      isPartnerAdmin: false
    };
  },

  computed: {
    hideLinks(): boolean {
      return !!this.$route.meta?.hideNavLinks;
    },

    hideMainSlot(): boolean {
      return !!this.$route.meta?.hideMainSlot;
    },

    homeLink(): string {
      if (this.isSubscriptionRoute) {
        return this.$router.currentRoute.path;
      } else {
        return this.isLoggedIn ? '/dashboard' : '/';
      }
    },

    isLandingPage(): boolean {
      return !!this.$route.meta?.landingPage;
    },

    isPaywalled(): boolean {
      return this.$store.getters['user/mustPurchaseSubscription'];
    },

    homeName(): string {
      return this.isLoggedIn ? 'dashboard' : 'home';
    },

    isLyftUser(): boolean {
      return this.$store.getters['user/isLyftUser'];
    },

    isLyftSubscription(): boolean {
      return this.$store.getters['user/getSubscriptions'][0]?.program?.name === 'lyft';
    },

    isSubscriber(): boolean {
      return this.$store.getters['user/getSubscriptions'].length > 0;
    },

    isSubscriptionRoute(): boolean {
      return !!this.$route.name?.match(/subscription/) && !this.$route.name?.match(/profile/);
    },

    accountIsConfirmed(): boolean {
      return this.$store.getters['user/isConfirmed'];
    },

    name(): string {
      const { firstName, lastName } = this.userProfile;
      let name = 'Profile';
      if (!isNullish(firstName) && !isNullish(lastName)) {
        name = `${firstName} ${lastName}`;
      } else if (!isNullish(firstName) && isNullish(lastName)) {
        name = `${firstName}`;
      }

      return name;
    },

    navbarBrandClasses(): Record<string, boolean> {
      return {
        'navbar-brand-lyft': !this.isSubscriptionRoute && this.isLyftUser,
        'navbar-brand-partner': this.isSubscriber || this.isSubscriptionRoute
      };
    },

    showCloseButtonInNav(): boolean {
      return !!this.$route.meta?.showCloseButtonInNav;
    },

    isAutoRepairRoute(): boolean {
      const isAppointmentRoute = this.$route.meta?.appointment || this.$route.meta?.appointmentNav;
      return !!this.autoRepairPartner && isAppointmentRoute;
    },

    autoRepairPartner(): string {
      return this.$store.getters['appointment/getPartner'];
    },

    offers(): PartnerOffer[] {
      return this.$store.getters['user/getPartnerOffers'] || [];
    },

    showSignUp(): boolean {
      return this.$route.name === 'sign-in';
    }
  },

  watch: {
    isLoggedIn: {
      immediate: true,
      handler(isLoggedIn: boolean) {
        if (isLoggedIn) this.fetchAdminPartners();
      }
    }
  },

  created(): void {
    this.$router.beforeEach((to, from, next) => {
      const toRouteName = to.name || '';
      const fromRouteName = from.name || '';
      if (this.transitionException.includes(toRouteName) || this.transitionException.includes(fromRouteName)) {
        this.transitionName = '';
        return next();
      }
      next();
    });
  },

  methods: {
    objectIsEmpty: objectIsEmpty,

    logOut() {
      authService.signOut().then(() => {
        this.$store.dispatch('user/logout', true);
      });
    },

    closeNavbar(): void {
      // NOTE: this is toggling the collapse, vue-bootstrap's collapse doesn't
      // allow explicit closing and opening unless it's tied to a v-model.
      // This method is only called when a navigation item is clicked, so we
      // can safely assume it's in view, and it will close the collapse.
      if (window.innerWidth < 768) this.$root.$emit('bv::toggle::collapse', 'nav-collapse');
    },

    navigateToAutoEssentials(): void {
      gtagService.event('auto_essentials_link_clicked', {
        from: 'navbar'
      });
      this.$router.push({ name: 'auto-essentials' });
    },

    fetchAdminPartners(): void {
      const partnerService = new PartnerService();
      partnerService.myAdminPartners().then((response) => {
        this.isPartnerAdmin = response?.length;
      });
    },

    scrollToLandingPageLogin(): void {
      this.$root.$emit('header-sign-in');
    }
  }
});
