





















import Vue, { PropType } from 'vue';
import { BFormInput } from 'bootstrap-vue';
import { isOneOf } from '@/concerns/validator';
import { debounce } from 'underscore';

const VALIDATION_DEBOUNCE_TIME = 300;

type InputType = 'text' | 'email' | 'phone' | 'password' | 'number' | 'tel';
const inputTypes: InputType[] = ['text', 'email', 'phone', 'password', 'number', 'tel']; // eslint-disable-line

export default Vue.extend({
  name: 'RequiredInput',

  components: {
    'b-form-input': BFormInput
  },

  props: {
    autocomplete: {
      type: String,
      default: 'on'
    },

    labelTitle: {
      type: String,
      default: ''
    },

    hasFocus: {
      type: Boolean,
      default: false
    },

    name: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      required: true
    },

    floatingLabels: {
      type: Boolean,
      default: true
    },

    required: {
      type: Boolean,
      default: true
    },

    type: {
      type: String as PropType<InputType>,
      default: 'text',
      validator: isOneOf(inputTypes)
    },

    validator: {
      type: Function,
      required: false,
      default: null
    }
  },
  data() {
    return {
      value: '',
      errors: [] as string[],
      valid: false,
      validated: false,
      error: ''
    };
  },
  computed: {
    showNameError(): boolean {
      return this.validated === true && this.valid === false;
    },
    fieldId(): String {
      return this.placeholder
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
          return index == 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '');
    }
  },
  watch: {
    value(newVal: string | number, oldVal: string | number): void {
      if (newVal === oldVal) return;
      this.$emit('name-update', this.value);
      this.error = '';
      this.validated = false;
      this.debounceValidate();
    }
  },
  mounted(): void {
    this.value = this.$attrs.value;
    if (this.hasFocus) {
      (this.$refs['required-input'] as BFormInput).focus();
    }
  },
  methods: {
    debounceValidate: debounce(function (this: any) {
      this.validate();
    }, VALIDATION_DEBOUNCE_TIME),
    validate(): void {
      //handle empty required inputs
      if (this.required && !this.value) this.error = `${this.placeholder} is required.`;
      //handle validation if validator function has been passed
      if (typeof this.validator === 'function') {
        if (!this.validator(this.value)) this.error = `Please enter a valid ${this.placeholder}`;
      }

      if (this.error === '') {
        this.valid = true;
        this.$emit('name-valid-update', this.valid);
      } else {
        this.$emit('error', this.errors);
        this.valid = false;
      }
      this.validated = true;
    }
  }
});
