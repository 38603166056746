


















































































import Vue from 'vue';
import { BModal, VBModal, BAlert } from 'bootstrap-vue';
import { CreditCard } from '@/types/CreditCard';
import segmentServices from '@/services/segmentServices';
import { toFileName, isNullish } from '@/utilities';
import { PaymentMethod } from '@/types/microservices/PaymentMethod';

export default Vue.extend({
  name: 'SelectCreditCard',

  components: {
    BAlert,
    BModal
  },

  directives: {
    VBModal
  },

  props: {
    offer: {
      type: Object,
      default: () => {}
    },

    serviceRequest: {
      type: Object,
      default: () => {}
    },

    tsPaymentMethods: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedCard: {} as CreditCard,
      selectedPaymentMethod: {} as PaymentMethod,
      alert: {
        message: '',
        type: ''
      },
      cardToDelete: {} as CreditCard | {} as any,
      loading: false
    };
  },

  computed: {
    userCreditCards(): (CreditCard & PaymentMethod)[] {
      const getter = this.tsPaymentMethods ? 'getPaymentMethods' : 'getCards';
      return this.$store.getters[`creditCards/${getter}`];
    },

    userPaymentMethods(): PaymentMethod[] {
      return this.$store.getters['creditCards/getPaymentMethods'];
    }
  },

  mounted(): void {
    this.selectedCard = this.userCreditCards[0];

    this.$emit('card-selected', this.selectedCard);
  },

  methods: {
    updateCard(card: CreditCard): void {
      this.$emit('update-card', card);
    },

    deleteCard(creditCard: CreditCard): void {
      this.cardToDelete = creditCard;
      (this.$refs['delete-confirm'] as BModal).show();
    },

    changeCard(card: CreditCard): void {
      this.selectedCard = card;
      this.$emit('card-selected', card);
    },

    changePaymentMethod(card: PaymentMethod): void {
      this.selectedPaymentMethod = card;
      this.$emit('card-selected', card);
    },

    confirmDeleteCard(creditCard: CreditCard): void {
      this.toggleCardLoading();
      this.loading = true;

      this.$store
        .dispatch('creditCards/removeCreditCard', creditCard)
        .then(() => {
          this.cardToDelete = {};
          this.recordSegmentEvent();
          this.alert = {
            type: 'success',
            message: `Your ${creditCard.type} ending in ${creditCard.last4} has been deleted.`
          };
        })
        .catch((response) => {
          this.toggleCardLoading();
          this.alert = {
            type: 'danger',
            message: response
          };
        })
        .finally(() => {
          this.loading = false;
        });
    },

    recordSegmentEvent(): void {
      if (!isNullish(this.serviceRequest) && !isNullish(this.offer)) {
        segmentServices.trackServiceRequestAndOffer('Payment Method Deleted', this.serviceRequest, this.offer);
      } else if (!isNullish(this.serviceRequest) && isNullish(this.offer)) {
        segmentServices.trackServiceRequest('Payment Method Deleted', this.serviceRequest);
      } else {
        segmentServices.trackGeneral('Payment Method Deleted', undefined);
      }
    },

    toggleCardLoading(): void {
      this.userCreditCards.forEach((card: any) => {
        if (card.id == this.cardToDelete.id) {
          card.loading = !card.loading;
        }
      });
    },

    //Repeating this method, should move to helper
    toFileName: toFileName
  }
});
