















import Vue from 'vue';
import { BCollapse } from 'bootstrap-vue';
import { ProgramPlanFaq } from '@/types/microservices/ProgramPlanFaq';
import { SortDirection } from '@/types/microservices/api';
import SubscriptionService from '@/services/microservices/subscriptionService';

export default Vue.extend({
  name: 'SubscriptionOptionsFaq',

  components: {
    BCollapse
  },

  data() {
    return {
      faqs: [] as ProgramPlanFaq[]
    };
  },

  mounted() {
    this.fetchFaqs();
  },

  methods: {
    fetchFaqs() {
      const subService = new SubscriptionService();
      subService
        .getSubscriptionFaqs({
          prop: 'id',
          order: SortDirection.ascending
        })
        .then((data) => (this.faqs = data));
    }
  }
});
