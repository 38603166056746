





































































import { BFormInput, BFormTextarea, BFormInvalidFeedback, BAlert } from 'bootstrap-vue';
import shopContactService from '@/services/shopContactService';
import { isEmail, isPhoneNumber } from '@/concerns/validator';
import CombinedComponent, { configMixin } from '@/mixins';

export default CombinedComponent(configMixin).extend({
  name: 'AddShopForm',
  components: {
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BAlert
  },
  props: {},
  data() {
    return {
      shopName: { value: '', touched: false },
      cityState: { value: '', touched: false },
      phoneNumber: { value: '', touched: false },
      shopEmail: { value: '', touched: false },
      notes: { value: '', touched: false },
      error: null,
      submitting: false
    };
  },
  computed: {
    canSubmit(): boolean {
      return !!(
        this.validateRequired(this.shopName) &&
        this.validateRequired(this.cityState) &&
        this.validateEmail(this.shopEmail) &&
        this.validatePhone(this.phoneNumber) &&
        !this.submitting
      );
    }
  },
  watch: {
    canSubmit(newValue: boolean) {
      this.$emit('form-validity-changed', newValue);
    }
  },
  methods: {
    validateRequired(field: { value: string; touched: boolean }): boolean | null {
      if (!field.touched) return null;
      return field.value.length > 0;
    },
    validateEmail(field: { value: string; touched: boolean }): boolean | null {
      if (!field.touched) return true;
      return isEmail({ allowBlank: true })(field.value);
    },
    validatePhone(field: { value: string; touched: boolean }): boolean | null {
      if (!field.touched) return true;
      return isPhoneNumber({ allowBlank: true })(field.value);
    },
    submit(): Promise<void> {
      this.submitting = true;

      const payload = {
        shopName: this.shopName.value,
        cityState: this.cityState.value,
        phoneNumber: this.phoneNumber.value,
        shopEmail: this.shopEmail.value,
        notes: this.notes.value
      };

      return shopContactService
        .createShopContactRequest(payload)
        .catch((e) => (this.error = e))
        .finally(() => (this.submitting = false));
    }
  }
});
