



















































import Vue, { PropType } from 'vue';

import { uniq } from 'underscore';

import { FormattedSubscriptionOption } from '@/types/FormattedSubscriptionOption';

export default Vue.extend({
  name: 'SubscriptionOptionsComparison',

  components: {},

  props: {
    subscriptionOptions: {
      type: Array as PropType<FormattedSubscriptionOption[]>,
      required: true
    }
  },

  data() {
    return {
      compareLineItems: [
        'List of local automotive service professionals near you',
        'Customer rating and reviews, amenities',
        '1000s of services to select from',
        'Upfront pricing on automotive repair',
        'Book service appointments online'
      ]
    };
  },

  computed: {
    hasYearlyPlan(): boolean {
      return this.subscriptionOptions.some((opt) => this.isYearly(opt));
    },

    dedupedOptions(): FormattedSubscriptionOption[] {
      return uniq(this.subscriptionOptions, (s) => s.name);
    }
  },

  methods: {
    getFrequencyAbbreviation(frequency: string) {
      return (
        {
          Yearly: 'yr',
          Monthly: 'mo',
          Quarterly: 'qtr'
        }[frequency] || ''
      );
    },

    isYearly(option: FormattedSubscriptionOption): boolean {
      return ['Yearly', 'fifteenMo'].includes(option.frequency!);
    },

    getSubheading(frequency: string) {
      return (
        {
          Free: 'Free Plan',
          Yearly: '12 Month Plan',
          Monthly: '1 Month Plan',
          Quarterly: '3 Month Plan'
        }[frequency] || ''
      );
    }
  }
});
