
























































import Vue, { PropType } from 'vue';

import { BAlert } from 'bootstrap-vue';

import requiredInput from '@/components/Input/RequiredInput.vue';
import BaseAddressInputs from '@/components/Shared/BaseAddressInputs.vue';
import BaseInput from '@/components/Shared/BaseInput.vue';
import segmentServices from '@/services/segmentServices';
import stripeService from '@/services/stripeService';
import { BillingAddress } from '@/types/ServiceRequest';
import { User } from '@/types/User';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faDonate } from '@fortawesome/pro-solid-svg-icons';

import { isNullish } from '../../utilities';

library.add(faDonate);

export default Vue.extend({
  name: 'AddCreditCard',

  //prettier-ignore
  components: {
    BAlert,
    'required-input': requiredInput,
    BaseAddressInputs,
    BaseInput,
  },

  props: {
    offer: {
      type: Object,
      default: null
    },

    serviceAddressValid: {
      type: Boolean,
      default: true
    },

    user: {
      type: Object as PropType<User>,
      default: () => ({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        returningCustomer: false,
        rewards: 0,
        status: ''
      })
    },

    serviceRequest: {
      type: Object,
      default: null
    },

    submitText: {
      type: String,
      default: 'Add Card'
    },

    displayCancelButton: {
      type: Boolean,
      default: true
    },

    displaySubmitButton: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      error: '',
      tokenData: {
        name: '',
        address_line1: '',
        address_line2: '',
        address_city: '',
        address_state: '',
        address_zip: '',
        address_country: 'US'
      },
      billingAddress: {
        addressLine1: '',
        addressLine2: '',
        addressCity: '',
        addressState: '',
        addressZipcode: '',
        addressCountry: ''
      } as BillingAddress,
      loading: false,
      countries: [] as { code: string; name: string }[],
      cardElementValid: false
    };
  },

  computed: {
    card() {
      return stripeService.initStripe();
    },
    nameValid(): boolean {
      return this.tokenData.name.length > 0;
    },

    addressValid(): boolean {
      const validZip = this.tokenData.address_zip.match(/^[0-9]{5}(-[0-9]{4})?$/);
      return this.tokenData.address_line1.length > 0 && !isNullish(validZip) && this.tokenData.address_city.length > 0;
    },

    formIsValid(): boolean {
      return this.nameValid && this.addressValid && this.serviceAddressValid && !this.loading && this.cardElementValid;
    }
  },

  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user.firstName || this.user.lastName) return;
        const firstName = this.user.firstName ? this.user.firstName : '';
        const lastName = this.user.lastName ? this.user.lastName : '';
        this.tokenData.name = `${firstName} ${lastName}`.trim();
      }
    },

    tokenData: {
      deep: true,
      handler() {
        this.$emit('billing-address-changed', this.billingAddress);
      }
    },

    formIsValid: {
      handler() {
        this.$emit('form-validity-changed', this.formIsValid);
      }
    }
  },

  mounted(): void {
    this.card.mount(this.$refs.cardElement);
    this.card.on('change', (e) => {
      this.cardElementValid = e?.complete ?? false;
    });
  },

  methods: {
    createToken(_e: Event): void {
      this.loading = true;
      stripeService
        .createStripeToken(this.card, this.tokenData)
        .then((token) => {
          this.saveCard(token);
        })
        .catch((error) => {
          // Failed to create the token
          this.error = error;
          this.loading = false;
        });
    },

    saveCard(stripeToken: stripe.Token): void {
      this.$store
        .dispatch('creditCards/addCreditCard', stripeToken)
        .then((response) => {
          this.recordSegmentEvent();
          this.$emit('card-saved', response);
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    recordSegmentEvent(): void {
      if (!isNullish(this.serviceRequest) && isNullish(this.offer)) {
        segmentServices.trackServiceRequest('Payment Method Added', this.serviceRequest); // This context doens't exist yet, but it handles it as a possibility.
      } else if (!isNullish(this.serviceRequest) && !isNullish(this.offer)) {
        segmentServices.trackServiceRequestAndOffer('Payment Method Added', this.serviceRequest, this.offer); // Called from the confirm step, where both service request and offer are in context
      } else {
        segmentServices.trackGeneral('Payment Method Added', {}); // Called from profile tab where service request and offer context don't exist
      }
    },

    updateBillingAddress(address: BillingAddress): void {
      // Update Stripe token
      this.tokenData.address_line1 = address.addressLine1;
      this.tokenData.address_line2 = address.addressLine2 || '';
      this.tokenData.address_city = address.addressCity;
      this.tokenData.address_state = address.addressState;
      this.tokenData.address_zip = address.addressZipcode;
      this.tokenData.address_country = address.addressCountry;

      this.billingAddress = address;
    },
    updateName(name: string) {
      this.tokenData.name = name;
    },

    confirmSetup(stripeKey: string, _callback: Function): Promise<stripe.SetupIntentResponse> {
      return new Promise((resolve) => {
        stripeService
          .stripeInstance()
          .confirmCardSetup(stripeKey, {
            payment_method: {
              card: this.card,
              billing_details: {
                name: this.tokenData.name,
                email: this.user.email
              }
            }
          })
          .then((result) => {
            resolve(result);
          });
      });
    }
  }
});
// 1. Create Token
// 2. Save card to user if needed
// 3. Accept Service Request offer with service request ID and offer ID and Card ID
