import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import router from '@/router';
import store from '@/store/store';

import { RetriableAxiosRequestConfig } from './types/microservices/api';

const config = (): AxiosRequestConfig => ({});

const httpClientMicro = axios.create(config());

httpClientMicro.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${store.state.auth.token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const maybeRetry = (config: RetriableAxiosRequestConfig): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    if (config.retried) {
      reject();
    } else {
      store
        .dispatch('auth/refreshToken')
        .then(() => {
          config.retried = true;
          config.headers.Authorization = `Bearer ${store.state.auth.token}`;
          resolve(httpClientMicro.request(config));
        })
        .catch((error) => reject(error));
    }
  });
};

/** Adding global response interceptors */
httpClientMicro.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const { response } = error;
    if (!response) return Promise.reject(error);

    if (response.status === 401) {
      return maybeRetry(error.config).catch(() => {
        store.dispatch('user/logout');
      });
    } else if (response.status === 429) {
      return router.push({ name: '429' }).catch((error: Error) => {
        if (error.name === 'NavigationDuplicated') return { data: {} };
        throw error;
      });
    }

    return Promise.reject(error);
  }
);

export { httpClientMicro };
