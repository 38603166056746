import { CartService } from '../ServiceRequest';
import { OwnedVehicle } from '../resources/OwnedVehicles';

export interface GPTResponse {
  explanation: string;
  question: string;
  services: Partial<CartService>[];
  vehicle: Pick<OwnedVehicle, 'year' | 'make' | 'model'>;
  session_id: string;
}

export enum GPTStage {
  SERVICES = 'services',
  VEHICLE = 'vehicle'
}

export enum GPTEndpoints {
  'services' = '/services',
  'vehicle' = '/vehicle'
}
