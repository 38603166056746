













import Vue, { PropType } from 'vue';
import { VBToggle } from 'bootstrap-vue';
import { User } from '@/types/User';
import localforage from 'localforage';
import { JoinPlusBannerConfig } from '@/constants/plus-banner';

Vue.directive('b-toggle', VBToggle);

export default Vue.extend({
  name: 'JoinPlusBanner',

  props: {
    bannerConfig: {
      type: Object as PropType<JoinPlusBannerConfig>,
      required: true
    },

    isLyftUser: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      submitting: false
    };
  },
  computed: {
    isNewLyftAccount(): boolean {
      const profile: User = this.$store.getters['user/getUserProfile'];
      return profile.newLyftAccount;
    },
    bannerStyle(): Record<string, string> {
      return {
        backgroundColor: this.bannerConfig.bannerColor
      };
    }
  },

  mounted() {
    if (this.isLyftUser) {
      localforage
        .getItem<boolean>('joinPlusLyftModalShown')
        .then((value: boolean | null) => this.showModalOnMount(value));
    }
  },

  methods: {
    showModalOnMount(modalShown: boolean | null): void {
      if (this.isNewLyftAccount && !modalShown) {
        this.$root.$emit('joinplus::modal::show');
        localforage.setItem<boolean>('joinPlusLyftModalShown', true);
      }
    },
    bannerClicked(): void {
      this.$root.$emit('joinplus::modal::show', 'banner');
    }
  }
});
