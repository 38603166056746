















































//Libraries
import Vue, { PropType } from 'vue';

//Types
import { OwnedVehicle } from '@/types/resources/OwnedVehicles';

export default Vue.extend({
  name: 'VehicleDropdown',

  props: {
    placeholder: {
      type: String,
      default: 'type your search term'
    },

    defaultData: {
      type: Array as PropType<OwnedVehicle[]>,
      default: () => []
    },

    homepage: {
      type: Boolean,
      default: true
    },

    defaultDataHover: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectableVehicles: [] as OwnedVehicle[],
      vehicleName: '',
      showDropdown: false,
      onDropdownList: false
    };
  },

  methods: {
    toggleDropdown(): void {
      this.showDropdown = !this.showDropdown;
    },

    handleActiveState() {
      if (!this.onDropdownList) {
        this.showDropdown = false;
      }

      if (this.vehicleName === '') {
        this.$emit('is-active', false);
        this.$emit('clear-selected-service');
      }
    },

    selectVehicle(event: Event, ownedVehicle: OwnedVehicle) {
      this.$emit('default-item-selected', ownedVehicle);
      this.$store.commit('onrampCart/setOwnedVehicle', ownedVehicle);

      this.blurSearchMenu();
      this.showDropdown = false;
      this.vehicleName = `${ownedVehicle.year} ${ownedVehicle.make} ${ownedVehicle.model}`;
    },

    formatVehicleName(item: OwnedVehicle): string {
      const term = `${item.year} ${item.make} ${item.model}`;
      return term;
    },

    blurSearchMenu(): void {
      this.$nextTick(() => {
        if (this.$refs['SearchListInput'] !== undefined) {
          (this.$refs.SearchListInput as HTMLElement).focus();
          (this.$refs.SearchListInput as HTMLElement).blur();
        }
      });
    }
  }
});
