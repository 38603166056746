import { get } from 'lodash/fp';

export interface AppConfig {
  erroredConfig: boolean;
  spDashboard: string;
  nuAdminUrl: string;
  adminDashboard: string;
  NODE_ENV: string;
  error?: unknown;
  VUE_APP_ENVIRONMENT?: string;
  VUE_APP_API_BASE_URL?: string;
  VUE_APP_STRIPE_PUBLIC_KEY?: string;
  VUE_APP_FACEBOOK_VERSION?: string;
  VUE_APP_FACEBOOK_KEY?: string;
  VUE_APP_SEGMENT_WEB_CLIENT_KEY?: string;
  VUE_APP_GTM_ID?: string;
  VUE_APP_GA_MEASUREMENT_ID?: string;
  VUE_APP_GOOGLE_API_KEY?: string;
  VUE_APP_CABLE_CLIENT_URL?: string;
  VUE_APP_ENVIRONMENT_URL?: string;
  VUE_APP_COVERAGE_API_BASE_URL?: string;
  VUE_APP_SUBSCRIPTION_API_BASE_URL?: string;
  VUE_APP_PARTNER_API_BASE_URL?: string;
  VUE_APP_CONFIGURATION_API_BASE_URL?: string;
  VUE_APP_AUTH_USERNAME?: string;
  VUE_APP_AUTH_PASSWORD?: string;
  VUE_APP_HOTJAR_SITE_ID: number;
  VUE_APP_HOTJAR_VERSION: number;
  VUE_APP_ONRAMP_AI_URL: string;
  VUE_APP_ROLLBAR_TOKEN: string;
}

const getEnvDefaults = (): Partial<AppConfig> => {
  return {
    erroredConfig: true,
    NODE_ENV: process.env.NODE_ENV,
    VUE_APP_AUTH_USERNAME: process.env.VUE_APP_AUTH_USERNAME,
    VUE_APP_AUTH_PASSWORD: process.env.VUE_APP_AUTH_PASSWORD
  };
};

const getNuAdminUrl = (env: string): string => {
  switch (env) {
    case 'production':
      return 'https://admin.openbay.com';
    case 'local':
      return 'http://localhost:8081';
    default:
      return `https://admin-${env}.openbay.com`;
  }
};

export class Config {
  config: AppConfig | {} = {};

  async setConfig(): Promise<Config> {
    try {
      const response = await fetch(`/config.json`);
      const json = await response.json();
      const envUrl = json['VUE_APP_ENVIRONMENT_URL'];
      const env = json['VUE_APP_ENVIRONMENT'];
      this.config = {
        ...json,
        ...getEnvDefaults(),
        spDashboard: `${envUrl}v2/sp/dashboard`,
        adminDashboard: `${envUrl}admin`,
        erroredConfig: false,
        nuAdminUrl: getNuAdminUrl(env)
      };
    } catch (error) {
      this.config = { ...getEnvDefaults(), error };
    }
    return this;
  }

  public get<K extends keyof AppConfig>(key: K): AppConfig[K] {
    if (!this.config.hasOwnProperty(key)) throw Error(`there is no value set for the key ${key}.`);
    else return get(key, this.config);
  }

  public has<K extends keyof AppConfig>(...keys: K[]): boolean {
    return keys.every((key) => this.config.hasOwnProperty(key));
  }
}

let config: Config;

export const fetchConfig = () => {
  if (config instanceof Config) return config;
  else {
    config = new Config();
    return config;
  }
};
