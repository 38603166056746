import { httpClient } from '@/api';
import { handleError } from '@/services/helpers';

export default {
  getFeatures() {
    return httpClient
      .get<Record<string, boolean>>('v4/features')
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw handleError(error);
      });
  }
};
