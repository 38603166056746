














































import { PropType } from 'vue';
import { CreditCard } from '@/types/CreditCard';
import AddCreditCard from '@/components/Payment/AddCreditCard.vue';
import { monetize } from '@/utilities';
import { BAlert } from 'bootstrap-vue';
import EditCreditCard from '@/components/Payment/EditCreditCard.vue';
import SelectCreditCard from '@/components/Payment/SelectCreditCard.vue';
import SignUpForm from '@/components/Auth/SignUpForm.vue';

// Components
import Spinner from '@/components/Shared/Spinner.vue';
import BaseAddressInputs from '@/components/Shared/BaseAddressInputs.vue';
import { BFormCheckbox, BFormGroup, BTooltip } from 'bootstrap-vue';

//Types
import { BillingAddress } from '@/types/ServiceRequest';
import { PaymentMethod } from '@/types/microservices/PaymentMethod';

import CombinedComponent, { userMixin } from '@/mixins';

export default CombinedComponent(userMixin).extend({
  name: 'PaymentForm',

  components: {
    SignUpForm,
    'add-credit-card': AddCreditCard,
    SelectCreditCard,
    EditCreditCard,
    BAlert,
    Spinner,
    BaseAddressInputs,
    BFormCheckbox,
    BFormGroup,
    BTooltip
  },

  props: {
    alertCopy: {
      type: String,
      default:
        'An Openbay+ paid plan gives subscribers access to discounted prices, the final discounted price of the service will be paid directly to the shop after service is completed.'
    }
  },

  data() {
    return {
      paymentProperties: Object as PropType<{ [index: string]: any }>,
      edittingCard: false,
      addingCard: false,
      cardToEdit: {},
      cardForPayment: {},
      loading: false,
      alert: {
        type: '',
        message: ''
      }
    };
  },

  computed: {
    billingAddress(): BillingAddress {
      return this.$store.getters['onrampCart/getBillingAddress'];
    },

    // Billing Address must have at least: Address Line 1, City, State, Zipcode
    billingAddressValid(): boolean {
      return this.$store.getters['onrampCart/getValidityOfBillingAddress'];
    },

    creditCards(): PaymentMethod[] {
      return this.$store.getters['creditCards/getPaymentMethods'];
    },

    price(): string {
      return 'TODO IMPLEMENT ME'; // monetize(this.offer.totalAfterRewards, 'dollars');
    },

    submitText(): string {
      // prettier-ignore
      return this.loading ? `Loading...` : `Join Openbay+ for $TODO a month.`;
    },

    headerButtonText(): string {
      if (this.addingCard) {
        return 'Select a Card';
      } else if (this.edittingCard) {
        return 'Cancel';
      } else {
        return 'Add a New Card';
      }
    },

    hasCreditCards(): boolean {
      return this.creditCards.length > 0;
    },

    paymentForm(): string {
      if (this.isLoggedIn && this.hasCreditCards && !this.addingCard) {
        return 'select-credit-card';
      } else {
        return 'add-credit-card';
      }
    },

    paymentFormProperties(): { [index: string]: any } {
      if (this.isLoggedIn && this.hasCreditCards && !this.addingCard) {
        //properties passed to select-credit-card
        return {
          creditCards: this.creditCards,
          price: this.price
        };
      } else {
        //properties passed to add-credit-card
        return {
          price: this.price,
          user: this.userProfile
        };
      }
    }
  },

  created(): void {
    this.$store
      .dispatch('creditCards/fetchPaymentMethods')
      .catch((error) => {
        if (error?.response?.status !== 401) {
          this.alert.message = error;
          this.alert.type = 'danger';
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    toggleForm(): void {
      if (!this.edittingCard) {
        this.addingCard = !this.addingCard;
      } else {
        this.edittingCard = false;
      }

      if (this.addingCard) {
        this.$emit('card-selected', null);
      } else if (this.cardForPayment) {
        this.$emit('card-selected', this.cardForPayment);
      }
    },

    cardSaved(card: CreditCard): void {
      this.cardForPayment = card.id;
      this.addingCard = false;
      this.accept();
    },

    accept(): void {},

    cancelEdit(): void {
      this.cardToEdit = {};
      this.edittingCard = false;
    },

    leaveEditState(_card: PaymentMethod): void {
      this.cardToEdit = {};
      this.edittingCard = false;
      this.alert = {
        type: 'success',
        message: 'Your card was successfully updated.'
      };
    },

    selectCard(card: PaymentMethod): void {
      this.cardForPayment = card;
      this.$emit('card-selected', card);
    },

    updateCard(card: PaymentMethod): void {
      this.cardToEdit = card;
      this.edittingCard = true;
    },

    confirmSetup(stripeKey: string): Promise<stripe.SetupIntentResponse> {
      return (this.$refs.formRef as any)?.confirmSetup(stripeKey);
    },

    formValidityChanged(isValid: boolean) {
      this.$emit('form-validity-changed', isValid);
    },

    confirmCardResult(result: stripe.PaymentIntentResponse) {
      this.$emit('confirm-card-result', result);
    },

    monetize: monetize
  }
});
