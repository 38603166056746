import { LabelConfig } from '@/types/LabelConfig';
import Vue from 'vue';

const configMixin = Vue.extend({
  computed: {
    labels(): LabelConfig {
      return this.$store.getters['config/labels'];
    }
  }
});

export { configMixin };
