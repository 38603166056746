import EmailValidator from 'email-validator';
import { contains, isNull, isString, isUndefined } from 'underscore';

// NOTE: These are functions which RETURN validator functions. That way, you can
// use them in Vue prop validators like:
//
//   props: {
//     someValue: {
//       type: Number,
//       validator: isOneOf([1, 2, 3]),
//     },
//   },
//
// ...or in plain JS/TS like:
//
//   const isOneOrTwoOrThree = isOneOf([1, 2, 3]);
//   isOneOrTwoOrThree(10); //=> false
//   isOneOrTwoOrThree(2);  //=> true

// returns true if the value is in the provided array
export const isOneOf = function <T>(list: T[]): (val: T) => boolean {
  return (val: T) => contains(list, val);
};

/* eslint-disable */
export const isPhoneNumber = function ({ allowBlank }: { allowBlank: boolean }): (value: any) => boolean {
  return (value: any) => {
    if (allowBlank && isUndefined(value)) return true;
    if (allowBlank && isNull(value)) return true;
    if (allowBlank && isString(value) && !value) return true;

    const digitized = `${value}`.replace(/\D/g, '');
    const patternValid = digitized.length === 10 || (digitized.length === 11 && digitized[0] === '1');
    return patternValid;
  };
};

export const isEmail = function ({ allowBlank }: { allowBlank: boolean }): (value: any) => boolean {
  return (value: any) => {
    if (allowBlank && isUndefined(value)) return true;
    if (allowBlank && isNull(value)) return true;
    if (allowBlank && isString(value) && !value) return true;

    const valueString = `${value}`.trim();
    return EmailValidator.validate(valueString);
  };
};

export function validZipcode(zipcode: string): boolean {
  const trimmedZipcode = zipcode.trim();
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(trimmedZipcode);
}
