import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faLyft } from '@fortawesome/free-brands-svg-icons/faLyft';
import { faPinterest } from '@fortawesome/free-brands-svg-icons/faPinterest';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faAward } from '@fortawesome/pro-duotone-svg-icons/faAward';
import { faCalendar as fadCalendar } from '@fortawesome/pro-duotone-svg-icons/faCalendar';
import { faCalendarCheck } from '@fortawesome/pro-duotone-svg-icons/faCalendarCheck';
import { faBadgeDollar } from '@fortawesome/pro-duotone-svg-icons/faBadgeDollar';
import { faBadgeCheck } from '@fortawesome/pro-duotone-svg-icons/faBadgeCheck';
import { faCreditCardFront } from '@fortawesome/pro-duotone-svg-icons/faCreditCardFront';
import { faCarMechanic } from '@fortawesome/pro-duotone-svg-icons/faCarMechanic';
import { faCheckCircle as fadCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle';
import { faCommentDollar as fadCommentDollar } from '@fortawesome/pro-duotone-svg-icons/faCommentDollar';
import { faCommentsAltDollar } from '@fortawesome/pro-duotone-svg-icons/faCommentsAltDollar';
import { faEye } from '@fortawesome/pro-duotone-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-duotone-svg-icons/faEyeSlash';
import { faFunnelDollar } from '@fortawesome/pro-duotone-svg-icons/faFunnelDollar';
import { faGift as fadGift } from '@fortawesome/pro-duotone-svg-icons/faGift';
import { faGlobeAmericas as fadGlobeAmericas } from '@fortawesome/pro-duotone-svg-icons/faGlobeAmericas';
import { faHandsHeart } from '@fortawesome/pro-duotone-svg-icons/faHandsHeart';
import { faMapMarkerCheck } from '@fortawesome/pro-duotone-svg-icons/faMapMarkerCheck';
import { faShieldCheck } from '@fortawesome/pro-duotone-svg-icons/faShieldCheck';
import { faTags as fadTags } from '@fortawesome/pro-duotone-svg-icons/faTags';
import { faThumbsUp as fadThumbsUp } from '@fortawesome/pro-duotone-svg-icons/faThumbsUp';
import { faUserChart } from '@fortawesome/pro-duotone-svg-icons/faUserChart';
import { faUserCheck } from '@fortawesome/pro-duotone-svg-icons/faUserCheck';
import { faWrench as fadWrench } from '@fortawesome/pro-duotone-svg-icons/faWrench';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { faAsterisk } from '@fortawesome/pro-light-svg-icons/faAsterisk';
import { faBus } from '@fortawesome/pro-light-svg-icons/faBus';
import { faCalendarDay } from '@fortawesome/pro-light-svg-icons/faCalendarDay';
import { faCalendarEdit } from '@fortawesome/pro-light-svg-icons/faCalendarEdit';
import { faCar } from '@fortawesome/pro-light-svg-icons/faCar';
import { faCarCrash as faCarCrashLight } from '@fortawesome/pro-light-svg-icons/faCarCrash';
import { faCarWash as faCarWashLight } from '@fortawesome/pro-light-svg-icons/faCarWash';
import { faCars } from '@fortawesome/pro-light-svg-icons/faCars';
import { faCarSide } from '@fortawesome/pro-light-svg-icons/faCarSide';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { faClipboardCheck } from '@fortawesome/pro-light-svg-icons/faClipboardCheck';
import { faComment } from '@fortawesome/pro-light-svg-icons/faComment';
import { faCommentDollar } from '@fortawesome/pro-light-svg-icons/faCommentDollar';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faGift } from '@fortawesome/pro-light-svg-icons/faGift';
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt';
import { faPencil } from '@fortawesome/pro-light-svg-icons/faPencil';
import { faRoad } from '@fortawesome/pro-light-svg-icons/faRoad';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faSignOut } from '@fortawesome/pro-light-svg-icons/faSignOut';
import { faStoreAlt } from '@fortawesome/pro-light-svg-icons/faStoreAlt';
import { faSun } from '@fortawesome/pro-light-svg-icons/faSun';
import { faTimes as faTimesLight } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faToolbox } from '@fortawesome/pro-light-svg-icons/faToolbox';
import { faTools } from '@fortawesome/pro-light-svg-icons/faTools';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle';
import { faUsersCog } from '@fortawesome/pro-light-svg-icons/faUsersCog';
import { faWifi } from '@fortawesome/pro-light-svg-icons/faWifi';
import { faWrench } from '@fortawesome/pro-light-svg-icons/faWrench';
import { faCheck as farCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faThumbsUp } from '@fortawesome/pro-regular-svg-icons/faThumbsUp';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faArrowToRight } from '@fortawesome/pro-solid-svg-icons/faArrowToRight';
import { faCalendarExclamation } from '@fortawesome/pro-solid-svg-icons/faCalendarExclamation';
import { faCar as faCarSolid } from '@fortawesome/pro-solid-svg-icons/faCar';
import { faCarCrash } from '@fortawesome/pro-solid-svg-icons/faCarCrash';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH';
import { faEnvelopeOpenText } from '@fortawesome/pro-solid-svg-icons/faEnvelopeOpenText';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faFrown } from '@fortawesome/pro-solid-svg-icons/faFrown';
import { faInfoCircle as faInfoCircleSolid } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { faMailbox } from '@fortawesome/pro-solid-svg-icons/faMailbox';
import { faMap } from '@fortawesome/pro-solid-svg-icons/faMap';
import { faMapMarker } from '@fortawesome/pro-solid-svg-icons/faMapMarker';
import { faSadTear } from '@fortawesome/pro-solid-svg-icons/faSadTear';
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar';
import { faChevronDoubleRight } from '@fortawesome/pro-light-svg-icons/faChevronDoubleRight';
import { faRedo } from '@fortawesome/pro-light-svg-icons/faRedo';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default function registerFontAwesome(): void {
  // Setup fontawesome library
  Vue.component('icon', FontAwesomeIcon);
  // Light Icons
  library.add(
    faTools,
    faRoad,
    faToolbox,
    faTrashAlt,
    faWrench,
    faSearch,
    faCar,
    faCarCrashLight,
    faCarWashLight,
    faChevronUp,
    faChevronDown,
    faInfoCircle,
    faArrowLeft,
    faArrowRight,
    faPencil,
    faCommentDollar,
    faEdit,
    faComment,
    faCalendarEdit,
    faMapMarkerAlt,
    faCalendarDay,
    faTimesLight,
    faCars,
    faAsterisk,
    faCarSide,
    faSun,
    faWifi,
    faBus,
    faClipboardCheck,
    faStoreAlt,
    faAngleRight,
    faHome,
    faUsersCog,
    faUserCircle,
    faSignOut,
    faChevronDoubleRight,
    faRedo
  );

  //Regular Icons
  library.add(faPlus, farCheck, faChevronRight, faChevronLeft, faTimes, faGift, faThumbsUp, faPlusCircle);

  //Solid Icons
  library.add(
    faCheck,
    faFrown,
    faStar,
    faMap,
    faMapMarker,
    faEnvelopeOpenText,
    faMailbox,
    faCheckCircle,
    faCircle,
    faExclamationTriangle,
    faExclamationCircle,
    faInfoCircleSolid,
    faEllipsisH,
    faArrowToRight,
    faCarSolid,
    faCarCrash,
    faCalendarExclamation,
    faSadTear
  );

  //DuoTone Icons
  library.add(
    faEye,
    faEyeSlash,
    fadCheckCircle,
    fadCommentDollar,
    faHandsHeart,
    fadGift,
    faShieldCheck,
    faAward,
    faFunnelDollar,
    faUserChart,
    faUserCheck,
    faCarMechanic,
    faCommentsAltDollar,
    faCalendarCheck,
    fadThumbsUp,
    faMapMarkerCheck,
    fadWrench,
    fadTags,
    fadGlobeAmericas,
    fadCalendar,
    faBadgeDollar,
    faBadgeCheck,
    faCreditCardFront
  );

  //Brands Icons
  library.add(faFacebookSquare, faPinterest, faTwitter, faLinkedinIn, faLyft);
}
