import { Component } from 'vue';
import VueRouter from 'vue-router';
import { Store } from 'vuex';

import fetchBaseResources from '@/init/fetchBaseResources';
import initLocalforage from '@/init/initLocalForage';
import { logger, setLogger } from '@/services/loggerService';
import { isNullish } from '@/utilities';

import App from './App.vue';
import { Config } from './init/app-config';
import router from './router';
import { initGtag } from './services/gtagService';
import store from './store/store';
import { FullState } from './store/types';

const initApp = async (_config: Config): Promise<{ router: VueRouter; store: Store<FullState>; App: Component }> => {
  setLogger(); // we modified the logger export to not initialize the logger class on module import.
  initGtag(); // same for gtag

  try {
    await initLocalforage();
  } catch (error) {
    logger.info(error);
  }

  try {
    await fetchBaseResources();
  } catch (error) {
    logger.info(error);
  }

  router.afterEach((to, from) => {
    const name = to.name || '';
    const path = to.path;
    const referrer = isNullish(from.name) ? document.referrer : from.fullPath;
    const title = document.title;
    const url = to.fullPath;
    const search = to.query;
    const properties = {
      path,
      referrer,
      search,
      title,
      url
    };

    if (!isNullish(window.analytics) && !to.meta?.disableTracking) analytics.page(undefined, name, properties);
  });

  return { router, store, App };
};

export default async (config: Config) => await initApp(config);
