import localforage from 'localforage';
import Vue from 'vue';
import Vuex, { Store, StoreOptions } from 'vuex';
import VuexPersistence, { AsyncStorage } from 'vuex-persist';

// Modules
import alerts from '@/store/modules/alerts';
import appointment from '@/store/modules/appointment';
import auth from '@/store/modules/auth';
import config from '@/store/modules/config';
import creditCards from '@/store/modules/creditcards';
import onrampCart from '@/store/modules/onrampCart';
import services from '@/store/modules/services';
import settings from '@/store/modules/settings';
import user from '@/store/modules/user';

import { FullState, RootState } from './types';

Vue.use(Vuex);

const vuexLocal = (): { plugin: (...args: any[]) => any } => {
  return new VuexPersistence({
    key: 'frontend_vo',
    storage: localforage as AsyncStorage,
    asyncStorage: true,
    modules: ['user', 'settings', 'services', 'onrampCart', 'config', 'appointment']
  });
};

const newRootState = (): RootState => ({
  version: '1.2.1'
});

const modules = {
  alerts,
  auth,
  creditCards,
  onrampCart,
  services,
  settings,
  user,
  config,
  appointment
};

const newRootStoreOptions = (): StoreOptions<RootState> => ({
  modules,
  state() {
    return newRootState();
  },
  plugins: [vuexLocal().plugin]
});

export const createStore = (): Store<FullState> => {
  const store = new Vuex.Store(newRootStoreOptions());

  return store as unknown as Store<FullState>;
};

const store = createStore();

export default store;
