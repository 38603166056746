
































































































import Vue, { PropType } from 'vue';

import emailInput from '@/components/Input/EmailInput.vue';
import passwordInput from '@/components/Input/PasswordInput.vue';
import nameInput from '@/components/Input/RequiredInput.vue';
import JoinPlusDeliveryFields from '@/components/Subscription/JoinPlus/JoinPlusDeliveryFields.vue';
import { SegmentUserType } from '@/enums/SegmentUserType';
import authService from '@/services/authService';
import segmentServices from '@/services/segmentServices';
import userService from '@/services/userService';
import { PartnerConfig } from '@/store/types';
import { LabelConfig } from '@/types/LabelConfig';
import { CreateUserRequest, User } from '@/types/User';
import { initial } from 'underscore';

export default Vue.extend({
  name: 'SignUpForm',

  components: {
    'password-input': passwordInput,
    'email-input': emailInput,
    'name-input': nameInput,
    JoinPlusDeliveryFields
  },

  props: {
    initialEmail: {
      type: String,
      default: ''
    },

    initialPhone: {
      type: String,
      default: ''
    },

    initialPassword: {
      type: String,
      default: ''
    },

    initialSubmitText: {
      type: String,
      required: false,
      default: 'Create my free Openbay account'
    },

    promoCode: {
      type: String,
      required: false,
      default: ''
    },

    initialFirstName: {
      type: String,
      default: ''
    },

    initialLastName: {
      type: String,
      default: ''
    },

    isJoinPlus: {
      type: Boolean,
      default: false
    },

    isDeliveryDriver: {
      type: Boolean,
      default: false
    },

    acquisitionSource: {
      type: String,
      required: false,
      default: ''
    },

    noAreaFlow: {
      type: Boolean,
      default: false
    },

    partnerConfig: {
      type: Object as PropType<PartnerConfig>,
      required: false,
      default: () => null
    },

    programSlug: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    const email = this.initialEmail;
    return {
      newUser: {
        firstName: this.initialFirstName,
        lastName: this.initialLastName,
        password: this.initialPassword,
        email,
        phoneNumber: this.initialPhone,
        contactViaTextMessage: false,
        isRetailUser: !this.isJoinPlus,
        acquisitionSource: this.acquisitionSource,
        partnerName: this.programSlug
      } as CreateUserRequest,
      error: '',
      submitText: this.initialSubmitText,
      emailValid: !!email.match(/@/),
      firstNameValid: !!this.initialFirstName,
      lastNameValid: !!this.initialFirstName,
      passwordValid: this.initialPassword.length > 6,
      loading: false
    };
  },

  computed: {
    allFieldsValid(): boolean {
      return this.firstNameValid && this.lastNameValid && this.passwordValid && this.emailValid;
    },

    phoneNumberExists(): boolean {
      return this.newUser.phoneNumber.length > 0;
    },

    labels(): LabelConfig {
      return this.$store.getters['config/labelsFromConfig'](this.partnerConfig);
    }
  },

  watch: {
    'newUser.phoneNumber': {
      handler(newVal: string) {
        if (!newVal) this.newUser.contactViaTextMessage = false;
      }
    }
  },

  mounted(): void {
    this.submitText = this.initialSubmitText;
    //If promo code is pass in as prop, add to request params.
    if (this.promoCode !== '') this.newUser.promoCode = this.promoCode;
    segmentServices.trackGeneral('Guest Registration Form Viewed', undefined);
  },

  methods: {
    updateFirstName(newFirstName: string) {
      this.newUser.firstName = newFirstName;
      this.$emit('update:firstName', newFirstName);
      this.$emit('update:initialFirstName', newFirstName);
    },

    updateFirstNameValidity(isValid: boolean) {
      this.firstNameValid = isValid;
    },

    updateLastName(newLastName: string) {
      this.newUser.lastName = newLastName;
      this.$emit('update:lastName', newLastName);
      this.$emit('update:initialLastName', newLastName);
    },

    updateLastNameValidity(isValid: boolean) {
      this.lastNameValid = isValid;
    },

    updatePassword(newPwd: string) {
      this.newUser.password = newPwd;
      this.$emit('update:password', newPwd);
      this.$emit('update:initialPassword', newPwd);
    },

    updatePasswordValidity(isValid: boolean) {
      this.passwordValid = isValid;
    },

    updateEmail(newEmail: string) {
      this.newUser.email = newEmail;
      this.$emit('update:email', newEmail);
      this.$emit('update:initialEmail', newEmail);
    },

    updateEmailValidity(isValid: boolean) {
      this.emailValid = isValid;
    },

    signUpUser(): Promise<void> {
      const routeName = this.$route.name || '';
      const newUserRoutes = ['sign-up', 'invite-page', 'subscription', 'appointment-sign-up'];
      this.loading = true;
      this.submitText = 'Loading...';

      if (newUserRoutes.includes(routeName)) return this.signUpNewUser();

      segmentServices.trackGeneral('Guest Registration Form Submitted');
      return this.updateUser();
    },

    onSuccessfulSignUp(): Promise<void> {
      this.$store.commit('user/setSubscriptionsLoaded', true);
      return this.$store
        .dispatch('auth/refreshToken')
        .then(() => this.$store.dispatch('user/fetchRequiredResources'))
        .then(() => {
          const user = this.$store.getters['user/getUserProfile'];
          this.trackRegistrationSuccess(user);
          this.$emit('auth-success');
        });
    },

    signUpNewUser(): Promise<void> {
      return this.$store
        .dispatch('user/createUser', this.newUser)
        .then(() => this.onSuccessfulSignUp())
        .catch((response) => {
          this.$emit('auth-fail', response);
          this.error = response;
          this.submitText = this.initialSubmitText;
        })
        .finally(() => (this.loading = false));
    },

    updateUser(): Promise<void> {
      return authService
        .updateUser(this.newUser)
        .then((_data) => userService.retrieveUserProfile())
        .then((profile) => this.$store.commit('user/setUserProfile', profile))
        .then(() => this.onSuccessfulSignUp())
        .catch((error) => {
          segmentServices.trackGeneral('Registration Failed', error);

          //returns either the email is taken (in which case, fire event and display PasswordChallengeForm), or a server error
          if (error.message && error.message.includes('E-mail is already associated with Facebook Login account.')) {
            this.$emit('existing-facebook-auth', this.newUser.email);
          } else if (error.includes('email has already been taken')) {
            this.$emit('email-fail', this.newUser.email);
          } else {
            this.$emit('auth-fail', error);
            this.error = error;
          }
          this.loading = false;
          this.submitText = this.initialSubmitText;
        });
    },

    driverCompaniesChanged(values: string[]) {
      this.newUser.deliveryDriverCompanies = values;
    },

    trackRegistrationSuccess(user: User): void {
      segmentServices.identifyUserWithData(user, SegmentUserType.RegisteredOpenbay);
      segmentServices.trackGeneral('Registration Succeeded', undefined);
    }
  }
});
