import ActionCableVue from 'actioncable-vue';
import BlackHoleObject from 'black-hole-object';
import { BAlert, ModalPlugin } from 'bootstrap-vue';
import { VueMaskDirective } from 'v-mask';
import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
import VueMeta from 'vue-meta';
import Hotjar from '@hotjar/browser';
import Rollbar from 'rollbar';

import Spinner from '@/components/Shared/Spinner.vue';
import registerFontAwesome from '@/init/registerFontAwesome';

/* eslint-disable prefer-rest-params */
import { fetchConfig } from './init/app-config';

declare global {
  interface Window {
    analytics: any;
    __PRERENDER_INJECTED: boolean;
  }
}

// Makes sure a 'prerender-trigger' event fires on the document at most five
// seconds after executing. If one happens before five seconds, it'll remove the
// failsafe one on the five-second timer, and remove its own listener. If one
// doesn't happen before five seconds, it'll dispatch one of its own, and then
// remove its own listener.
const failsafePrerenderTriggerEvent = () => {
  const prerenderTriggerTimeout = setTimeout(() => {
    document.dispatchEvent(new Event('prerender-trigger'));
  }, 5000);

  const removePrerenderTriggerTimeout = () => {
    window.clearTimeout(prerenderTriggerTimeout);
    document.removeEventListener('prerender-trigger', removePrerenderTriggerTimeout);
  };

  document.addEventListener('prerender-trigger', removePrerenderTriggerTimeout);
};

Vue.config.productionTip = false;

Vue.component('spinner', Spinner);
Vue.component('b-alert', BAlert);

Vue.use(ModalPlugin);

Vue.use(VueMeta);
Vue.use(VueLazyload);

// Directives
Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el: { focus: () => void }, binding) {
    // Focus the element
    if (!binding.hasOwnProperty('value') || binding.value) {
      el.focus();
    }
  }
});

Vue.directive('mask', VueMaskDirective);
Vue.extend();
registerFontAwesome();

const activateGtm = (w: Window, d: Document, s: string, l: keyof Window, i: string) => {
  // @ts-expect-error
  w[l] = w[l] || [];
  w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
  const f = d.getElementsByTagName(s)[0],
    j = d.createElement(s),
    dl = l != 'dataLayer' ? '&l=' + l : '';
  // @ts-expect-error
  j.async = true;
  // @ts-expect-error
  j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
  // @ts-expect-error
  f.parentNode.insertBefore(j, f);
};
const enableGoogleAnalytics = async (googleAnalyticsId: string) => {
  activateGtm(window, document, 'script', 'dataLayer', googleAnalyticsId);
};

fetchConfig()
  .setConfig()
  .then(async (config) => {
    // If the rendered route doesn't emit a completion event of its own, do one
    // after 5 seconds so we don't hang forever during the prerender step at
    // build time.
    failsafePrerenderTriggerEvent();

    if (config.get('VUE_APP_ENVIRONMENT') !== 'local') {
      Vue.prototype.$rollbar = new Rollbar({
        accessToken: config.get('VUE_APP_ROLLBAR_TOKEN'),
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
          environment: config.get('VUE_APP_ENVIRONMENT'),
          code_version: '1.0.0',
          custom_data: {}
        }
      });

      Vue.config.errorHandler = (err, vm, _info) => {
        vm.$rollbar.error(err);
        throw err; // rethrow
      };
    }

    if (!window.__PRERENDER_INJECTED) {
      const googleAnalyticsSegmentKey = fetchConfig().get('VUE_APP_SEGMENT_WEB_CLIENT_KEY') as string;
      const googleAnalyticsId = fetchConfig().get('VUE_APP_GTM_ID') as string;
      analytics.load(googleAnalyticsSegmentKey);
      await enableGoogleAnalytics(googleAnalyticsId);
    }
    if (config.get('NODE_ENV') === 'test') {
      window.analytics = new BlackHoleObject();
    }

    if (config.has('VUE_APP_HOTJAR_SITE_ID', 'VUE_APP_HOTJAR_VERSION')) {
      const hotjarId = config.get('VUE_APP_HOTJAR_SITE_ID');
      const hotjarVersion = config.get('VUE_APP_HOTJAR_VERSION');
      Hotjar.init(hotjarId, hotjarVersion);
    }

    const actionCableVueOptions = {
      debug: true,
      debugLevel: 'error',
      connectionUrl: fetchConfig().get('VUE_APP_CABLE_CLIENT_URL') as string,
      connectImmediately: true
    };

    Vue.use(ActionCableVue, actionCableVueOptions);

    const { router, store, App } = await require('./bootstrap-app').default(config);
    new Vue({
      router,
      store,
      components: {
        BAlert,
        Spinner
      },
      render: (h) => h(App)
    }).$mount('#app');
  });
