export default [
  {
    path: '/maintenance',
    name: 'maintenance-root',
    component: () => import(/* webpackChunkName: "maintenance" */ '@/views/Maintenance.vue'),
    meta: {
      public: true
    },
    children: [
      {
        name: 'maintenance-make-model',
        path: ':makeModel', // ie. `/honda-civic`, `/toyota-camry`
        meta: {
          fluidContainer: true,
          hideMainSlot: true,
          disableTracking: true,
          whiteBackground: true
        }
      },
      {
        name: 'maintenance-make-model-year',
        path: ':makeModel/:year',
        meta: {
          fluidContainer: true,
          hideMainSlot: true,
          disableTracking: true,
          whiteBackground: true,
          preventScrollJump: true
        }
      },
      {
        name: 'maintenance-aces-year-make-model',
        path: ':year/:make/:model',
        meta: {
          fluidContainer: true,
          hideMainSlot: true,
          disableTracking: true,
          whiteBackground: true,
          preventScrollJump: true
        }
      }
    ]
  },
  {
    path: '/maintenance-schedule',
    name: 'maintenance-schedules',
    component: () => import(/* webpackChunkName: "maintenance-schedules" */ '@/views/MaintenanceVehicleSelection.vue'),
    meta: {
      public: true
    },
    children: [
      {
        name: 'maintenance-schedules-makes',
        path: 'makes',
        component: () =>
          import(
            /* webpackChunkName: "maintenance-schedules-models" */ '@/components/MaintenanceSchedule/MakeSelector.vue'
          ),
        meta: {
          fluidContainer: true,
          hideMainSlot: true,
          disableTracking: true,
          whiteBackground: true
        }
      },
      {
        name: 'maintenance-schedules-models',
        path: ':make',
        component: () =>
          import(
            /* webpackChunkName: "maintenance-schedules-models" */ '@/components/MaintenanceSchedule/ModelSelector.vue'
          ),
        meta: {
          fluidContainer: true,
          hideMainSlot: true,
          disableTracking: true,
          whiteBackground: true
        }
      },
      {
        name: 'maintenance-schedules-years',
        path: ':make/:model',
        component: () =>
          import(
            /* webpackChunkName: "maintenance-schedules-models" */ '@/components/MaintenanceSchedule/YearSelector.vue'
          ),
        meta: {
          fluidContainer: true,
          hideMainSlot: true,
          disableTracking: true,
          whiteBackground: true
        }
      }
    ]
  }
];
