import { RouteConfig } from 'vue-router';

const Appointment = () => import(/* webpackChunkName: "appointment" */ '@/views/Appointment.vue');
const AppointmentCreation = () =>
  import(/* webpackChunkName: "appointment" */ '@/components/Appointment/AppointmentCreation.vue');
const VehicleSelector = () =>
  import(/* webpackChunkName: "appointment" */ '@/components/Appointment/VehicleSelector.vue');
const AppointmentSelector = () =>
  import(/* webpackChunkName: "appointment" */ '@/components/Onramp/Appointment/AppointmentSelector.vue');
const Notes = () => import(/* webpackChunkName: "appointment" */ '@/components/Appointment/Notes.vue');

const SignUp = () => import(/* webpackChunkName: "appointment" */ '@/components/Appointment/SignUp.vue');
const AppointmentConfirmation = () =>
  import(/* webpackChunkName: "appointment" */ '@/components/Appointment/AppointmentConfirmation.vue');

const AppointmentConfirmed = () =>
  import(/* webpackChunkName: "appointment" */ '@/components/Appointment/AppointmentConfirmed.vue');

export default [
  {
    path: '/book-appointment',
    name: 'book-appointment',
    component: Appointment,
    meta: {
      transitionName: 'slide-left',
      public: true,
      appointment: true
    },
    children: [
      {
        path: 'vehicles',
        name: 'vehicles',
        component: AppointmentCreation,
        redirect: { name: 'add-vehicle-appointment' },
        meta: {
          public: true,
          appointment: true
        },
        children: [
          {
            path: 'vehicle',
            name: 'vehicle',
            component: VehicleSelector,
            meta: {
              step: 'vehicle',
              transitionName: 'slide-left',
              title: 'Tell us about your vehicle',
              appointmentNav: true,
              showCloseButtonInNav: true
            },
            children: [
              {
                path: 'add',
                name: 'add-vehicle-appointment',
                component: VehicleSelector,
                meta: {
                  step: 'vehicle',
                  title: 'Tell us about your vehicle',
                  appointmentNav: true,
                  showCloseButtonInNav: true
                }
              },
              {
                path: 'add/make',
                name: 'add-vehicle-appointment-make',
                component: VehicleSelector,
                meta: {
                  step: 'vehicle',
                  title: 'Tell us about your vehicle',
                  appointmentNav: true,
                  showCloseButtonInNav: true
                }
              },
              {
                path: 'add/model',
                name: 'add-vehicle-appointment-model',
                component: VehicleSelector,
                meta: {
                  step: 'vehicle',
                  title: 'Tell us about your vehicle',
                  appointmentNav: true,
                  showCloseButtonInNav: true
                }
              },
              {
                path: 'add/trim',
                name: 'add-vehicle-appointment-trim',
                component: VehicleSelector,
                meta: {
                  step: 'vehicle',
                  title: 'Tell us about your vehicle',
                  appointmentNav: true,
                  showCloseButtonInNav: true
                }
              },
              {
                path: 'add/engine',
                name: 'add-vehicle-appointment-engine',
                component: VehicleSelector,
                meta: {
                  step: 'vehicle',
                  title: 'Tell us about your vehicle',
                  appointmentNav: true,
                  showCloseButtonInNav: true
                }
              },
              {
                path: 'add/subModel',
                name: 'add-vehicle-appointment-subModel',
                component: VehicleSelector,
                meta: {
                  step: 'vehicle',
                  title: 'Tell us about your vehicle',
                  appointmentNav: true,
                  showCloseButtonInNav: true
                }
              },
              {
                path: 'add/mileage',
                name: 'add-vehicle-appointment-mileage',
                component: VehicleSelector,
                //@ts-ignore
                beforeEnter: (_to, from, next) => {
                  if (from.name === 'interval-services') {
                    next('/service-request/vehicle');
                  } else {
                    next();
                  }
                },
                meta: {
                  step: 'vehicle',
                  title: 'How many miles are on your vehicle?',
                  transitionName: 'slide-left',
                  appointmentNav: true,
                  showCloseButtonInNav: true
                }
              }
            ]
          }
        ]
      },
      {
        path: 'schedule',
        name: 'appointment-schedule',
        component: AppointmentSelector,
        props: true,
        meta: {
          title: 'Choose an appointment time',
          step: 'schedule',
          appointmentNav: true,
          showCloseButtonInNav: true,
          public: true
        }
      },
      ,
      {
        path: 'notes',
        name: 'appointment-notes',
        component: Notes,
        props: true,
        meta: {
          title: 'Notes for the shop',
          step: 'notes',
          appointmentNav: true,
          showCloseButtonInNav: true,
          public: true
        }
      },
      {
        path: 'sign-up',
        name: 'appointment-sign-up',
        component: SignUp,
        props: true,
        meta: {
          step: 'sign-up',
          title: 'Create your free account.',
          appointmentNav: true,
          showCloseButtonInNav: true,
          public: true
        }
      },
      {
        path: 'activate-account',
        name: 'appointment-activate-account',
        component: SignUp,
        props: true,
        meta: {
          step: 'sign-up',
          title: 'Confirm your account.',
          appointmentNav: true,
          showCloseButtonInNav: true,
          public: true
        }
      },
      {
        path: 'confirm',
        name: 'appointment-confirm',
        component: AppointmentConfirmation,
        props: true,
        meta: {
          title: 'Confirm your appointment details.',
          step: 'confirm',
          appointmentNav: true,
          showCloseButtonInNav: true,
          hideSidebar: true,
          public: false
        }
      },
      {
        path: ':id/confirmation',
        name: 'appointment-confirmation',
        component: AppointmentConfirmed,
        props: true,
        meta: {
          title: '',
          step: 'confirmation',
          public: false,
          hideSidebar: true,
          appointment: true
        }
      }
    ]
  }
] as RouteConfig[];
