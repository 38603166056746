import { AxiosError } from 'axios';

import { httpClient } from '@/api';
import { AppointmentSlotsDocument } from '@/types/resources/AppointmentProposals';
import { StandaloneAppointmentParams } from '@/types/StandaloneAppointment';

import { handleError, handleSuccess } from './helpers';

export default {
  createAppointment(payload: StandaloneAppointmentParams) {
    return httpClient
      .post(`v3/standalone_appointments`, payload)
      .then((response) => handleSuccess(response))
      .catch((error: AxiosError | string) => {
        if (typeof error !== 'string') throw handleError(error);
        else throw error;
      });
  },

  cancelAppointment(id: number): Promise<void> {
    return httpClient
      .put(`v3/standalone_appointments/${id}/cancel`)
      .then((response) => handleSuccess(response))
      .catch((error) => {
        throw handleError(error);
      });
  },

  fetchAppointmentSlots(locationId: number) {
    return httpClient
      .get<AppointmentSlotsDocument>(`v3/standalone_appointments/${locationId}/appointment_proposals`)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        throw handleError(error);
      });
  }
};
