import { AxiosError } from 'axios';
import moment from 'moment';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import appointmentService from '@/services/appointmentService';
import { handleError } from '@/services/helpers';
/* eslint-disable no-param-reassign */
import { OwnedVehicle } from '@/types/resources/OwnedVehicles';
import { LocationFull } from '@/types/ServiceProvider';
import { StandaloneAppointment, StandaloneAppointmentParams } from '@/types/StandaloneAppointment';

import { AppointmentState, RootState } from '../types';

const initialState = (): AppointmentState => ({
  vehicleToCreate: null,
  ownedVehicle: null,
  location: null,
  notes: '',
  phone: '',
  appointmentSlot: null,
  didJustRegister: false,
  partner: '',
  carWash: false,
  collision: false
});

const state = initialState();

const getters: GetterTree<AppointmentState, RootState> = {
  getAppointmentSlot: (state) => state.appointmentSlot,

  getOwnedVehicle: (state) => state.ownedVehicle,

  getVehicleToCreate: (state) => state.vehicleToCreate,

  getLocation: (state) => state.location,

  getNotes: (state) => state.notes,

  getPhone: (state) => state.phone,

  didJustRegister: (state) => state.didJustRegister,

  getAppointmentParams: (state): StandaloneAppointmentParams | null => {
    if (!(state.appointmentSlot && state.ownedVehicle && state.location)) return null;

    return {
      serviceStationLocationId: state.location.id,
      vehicle: state.ownedVehicle!,
      notes: state.notes,
      scheduledAtDateString: state.appointmentSlot?.day,
      scheduledAtTimeString: moment.parseZone(state.appointmentSlot?.proposedTime).format('HH:mm'),
      phone: state.phone,
      partner: state.partner || null,
      carWash: state.carWash,
      collision: state.collision
    };
  },

  getCarWash: (state) => state.carWash,

  getPartner: (state) => state.partner,

  getCollision: (state) => state.collision
};

const actions: ActionTree<AppointmentState, RootState> = {
  createAppointment(_context, appointmentParams: StandaloneAppointmentParams): Promise<StandaloneAppointment> {
    return appointmentService.createAppointment(appointmentParams).catch((error: AxiosError) => {
      throw handleError(error);
    });
  }
};

const mutations: MutationTree<AppointmentState> = {
  clearOwnedVehicle(state) {
    state.ownedVehicle = null;
  },

  setAppointmentSlot(state, appointmentSlot) {
    state.appointmentSlot = appointmentSlot;
  },

  setLocation(state, location: LocationFull) {
    state.location = location;
  },

  setType(state, type: string) {
    switch (type) {
      case 'collision':
        state.carWash = false;
        state.collision = true;
        break;
      case 'car-wash':
        state.carWash = true;
        state.collision = false;
        break;
      case 'appointment':
        state.carWash = false;
        state.collision = false;
        break;
    }
  },

  setNotes(state, notes: string) {
    state.notes = notes;
  },

  setPhone(state, phone: string) {
    state.phone = phone;
  },

  setOwnedVehicle(state, ownedVehicle) {
    state.ownedVehicle = ownedVehicle;
  },

  setVehicleToCreate(state, vehicleRequest: Partial<OwnedVehicle>) {
    state.vehicleToCreate = vehicleRequest;
  },

  clearVehicleToCreate(state) {
    state.vehicleToCreate = null;
  },

  reset(state) {
    Object.assign(state, initialState());
  },

  setJustRegistered(state, value: boolean) {
    state.didJustRegister = value;
  },

  setPartner(state, partner: string) {
    state.partner = partner;
  }
};

const AppointmentStore: Module<AppointmentState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default AppointmentStore;
