import { findWhere } from 'underscore';

import {
  DocumentDefinition,
  ResourceDataDocument,
  ResourceIdentifierObject,
  ResourceObject,
  ResourcesDataDocument
} from '@/types/JsonSpec';
import { isNullish } from '@/utilities';

function findRelatedResourceInIncludes<T extends ResourceIdentifierObject>(
  relationship: T,
  included: ResourceObject<any>[]
): any {
  if (isNullish(relationship)) return null;
  return (
    findWhere(included, {
      id: relationship.id,
      type: relationship.type
    }) || null
  );
}

function attributesFromResource<Def extends DocumentDefinition, Res extends ResourceObject<Def>>(
  resource: Res | null
): Partial<Res['attributes']> {
  if (isNullish(resource)) return {};
  return resource.attributes || {};
}

function processResource(resource: null | undefined, included: null | undefined): null;
function processResource<Def extends DocumentDefinition>(
  resource: ResourceObject<Def>,
  included: ResourceObject<Def>[]
): Def['Attributes'];
function processResource<Def extends DocumentDefinition>(
  resource: ResourceObject<Def> | null | undefined,
  included: ResourceObject<Def>[] | null | undefined
): Def['Attributes'] | null {
  if (isNullish(resource)) return null;
  if (isNullish(resource.attributes)) return resource;

  const resourceModel = {} as any;
  const attributes = attributesFromResource(resource);
  Object.assign(resourceModel, attributes);

  const relationships = resource.relationships;
  if (isNullish(relationships)) return resourceModel;

  Object.keys(relationships!).forEach((relationshipName) => {
    const relationshipData = relationships[relationshipName].data;
    if (Array.isArray(relationshipData)) {
      resourceModel[relationshipName] = relationshipData.map((item) => {
        if (isNullish(included)) return null;
        const relatedResource = findRelatedResourceInIncludes(item, included);

        return processResource(relatedResource, included);
      });
    } else {
      const relatedResource = included && findRelatedResourceInIncludes(relationshipData, included);

      resourceModel[relationshipName] = processResource(relatedResource, included as any);
    }
  });

  return resourceModel;
}
export function processResponse<Def extends DocumentDefinition>(
  dataDocument: ResourcesDataDocument<Def>
): Def['Attributes'][];
export function processResponse<Def extends DocumentDefinition>(
  dataDocument: ResourceDataDocument<Def>
): Def['Attributes'];
export function processResponse<Def extends DocumentDefinition>(
  dataDocument: ResourcesDataDocument<Def> | ResourceDataDocument<Def>
): Def['Attributes'] | Def['Attributes'][] {
  const data = dataDocument.data;
  const included = dataDocument.included;
  const resource = Array.isArray(data)
    ? data.map((resource) => processResource(resource, included!))
    : processResource(data, included!);

  return resource;
}
