import localforage from 'localforage';

import { logger } from '@/services/loggerService';

const BUILD_HASH = process.env.VUE_APP_BUILD_HASH || '';

function createInstance(config: LocalForageOptions) {
  localforage.config(config);
  localforage.createInstance(config);
  localStorage.setItem('deployment-hash', BUILD_HASH);
}

export default async function initLocalforage(): Promise<void> {
  const previousHash = localStorage.getItem('deployment-hash');

  try {
    await localforage.dropInstance({ name: `frontend_vo` });
  } catch (error) {
    logger.info(error);
  }

  if (previousHash !== BUILD_HASH) {
    try {
      await localforage.dropInstance({ name: `frontend_vo_${previousHash}` });
    } catch (error) {
      logger.info(error);
    }
  }

  createInstance({
    name: `frontend_vo_${BUILD_HASH}`,
    version: 1.0,
    storeName: 'ob_frontend_vo_storage'
  });
}
