import { AxiosRequestConfig } from 'axios';

export interface Pagination {
  skip: number;
  take: number;
  total: number;
}

export enum SortDirection {
  ascending = 'ascending',
  descending = 'descending'
}

export interface Sort<T> {
  prop: T;
  order: SortDirection;
}

export interface ApiListResponse<T> {
  data: T[];
  pagination: Pagination;
}

export interface RetriableAxiosRequestConfig extends AxiosRequestConfig {
  retried?: boolean;
}
