import { httpClient } from '@/api';
import { handleError, handleSuccess } from '@/services/helpers';
import { ShopContactRequest } from '@/types/ShopContactRequest';

export default {
  createShopContactRequest(payload: ShopContactRequest) {
    return httpClient
      .post(`v3/shop_contact_requests`, payload)
      .then((response) => {
        return handleSuccess(response);
      })
      .catch((error) => {
        handleError(error);
      });
  }
};
