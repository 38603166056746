/* eslint-disable no-param-reassign */
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

import servicesService from '@/services/servicesService';
import { DiscountServicePrice } from '@/types/resources/DiscountServicePrices';
import { Service } from '@/types/Services';

import { LyftEligibilityPayload, RootState, ServicesState } from '../types';

const initialState = (): ServicesState => ({
  services: [
    {
      id: 0,
      name: '',
      categoryName: '',
      note: '',
      autoquotable: false,
      enabled: true
    }
  ],
  discountPackages: [],
  popularServices: [],
  describeProblem: [],
  serviceCatalog: [],
  chatGPTResponse: ''
});

const state = initialState();

const getters: GetterTree<ServicesState, RootState> = {
  getServices: (state) => state.services.filter((service) => service.enabled),
  getService: (state) => (id: number) => {
    return state.services.find((service) => {
      return service.id == id;
    });
  },
  getDiscountPackages: (state) => {
    return state.discountPackages;
  },
  getPopularServiceInterviews: (state) => {
    return state.popularServices;
  },
  getDescribeProblemInterviews: (state) => {
    return state.describeProblem;
  },
  getServiceCatalogInterviews: (state) => {
    return state.serviceCatalog;
  }
};

const actions: ActionTree<ServicesState, RootState> = {
  setServices({ commit }: any, services: Service[]) {
    commit('setServices', services);
  },
  fetchServices({ commit }: any): Promise<string> {
    return servicesService
      .fetchServices()
      .then((servicesResponse) => {
        commit('setServices', servicesResponse);
        return 'Successfully fetched services.';
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchDiscountPackages({ commit }: any, payload: LyftEligibilityPayload): Promise<string> {
    return servicesService
      .fetchLyftPackages(payload.vehicleId, payload.zipcode)
      .then((response: DiscountServicePrice[] | string) => {
        //This service returns either the packages for the vehicle or the eligibility status of the zipcode or vehicle for the Lyft program.
        if (Array.isArray(response)) {
          commit('setDiscountPackages', response);
          return 'Successfully fetched packages.';
        } else {
          commit('setDiscountPackages', []);
          return response;
        }
      })
      .catch((error: string) => {
        throw error;
      });
  },
  fetchPopularServices({ commit }: any): Promise<string> {
    return servicesService
      .fetchServiceSelectionCategory('Popular Services')
      .then((selectionCategory) => {
        commit('setPopularServices', selectionCategory);
        return 'Successfully fetched popular services.';
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchDescribeProblem({ commit }: any): Promise<string> {
    return servicesService
      .fetchServiceSelectionCategory('Diagnosis / Describe Problem')
      .then((selectionCategory) => {
        commit('setDescribeProblem', selectionCategory);
        return 'Successfully fetched popular services.';
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchServiceCatalog({ commit }: any): Promise<string> {
    return servicesService
      .fetchServiceSelectionCategory('Service Catalog')
      .then((selectionCategory) => {
        commit('setServiceCatalog', selectionCategory);
        return 'Successfully fetched popular services.';
      })
      .catch((error) => {
        throw error;
      });
  },
  reset({ commit }: any) {
    commit('reset');
  },
  fetchViaChatGPT({ commit }: any, params: any): Promise<any> {
    return servicesService
      .fetchViaChatGPT(params.searchTerm, params.sessionId, params.stage)
      .then((response: any) => {
        commit('setChatGPTResponse', response);
        return response;
      })
      .catch((error: any) => {
        throw error;
      });
  }
};

const mutations: MutationTree<ServicesState> = {
  setServices(state, services: Service[]) {
    state.services = services;
  },
  setDiscountPackages(state, packages: DiscountServicePrice[]) {
    state.discountPackages = packages;
  },
  setPopularServices(state, selectionCategory) {
    state.popularServices = selectionCategory;
  },
  setDescribeProblem(state, selectionCategory) {
    state.describeProblem = selectionCategory;
  },
  setServiceCatalog(state, selectionCategory) {
    state.serviceCatalog = selectionCategory;
  },
  resetPackages(state) {
    state.discountPackages = [];
  },
  reset(state) {
    Object.assign(state, initialState());
  },
  setChatGPTResponse(state, response: string) {
    state.chatGPTResponse = response;
  }
};

const ServicesStore: Module<ServicesState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default ServicesStore;
