


















































import Vue from 'vue';
import RequiredInput from '@/components/Input/RequiredInput.vue';
import StateSelect from '@/components/Input/StatesSelect.vue';
import CountrySelect from '@/components/Input/CountrySelect.vue';

export default Vue.extend({
  name: 'BaseAddressInputs',

  components: {
    CountrySelect,
    RequiredInput,
    StateSelect
  },

  props: {
    defaultState: {
      type: String,
      required: false,
      default: ''
    },

    defaultCountry: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      address: {
        addressLine1: '',
        addressLine2: '',
        addressCity: '',
        addressZipcode: '',
        addressState: '',
        addressCountry: ''
      },
      zipCodeValid: false,
      cityValid: false,
      address1Valid: false
    };
  },

  watch: {
    address: {
      deep: true,
      handler() {
        this.$emit('address-updated', this.address);
      }
    }
  },

  methods: {
    updateZipcodeValidity(isValid: boolean) {
      this.zipCodeValid = isValid;
    },

    updateCityValidity(isValid: boolean) {
      this.cityValid = isValid;
    },

    updateAddress1Validity(isValid: boolean) {
      this.address1Valid = isValid;
    },

    updateAddress1(addressLine1: string): void {
      this.address.addressLine1 = addressLine1;
      this.$emit('update-address1', addressLine1);
    },

    updateAddress2(addressLine2: string): void {
      this.address.addressLine2 = addressLine2;
      this.$emit('update-address2', addressLine2);
    },

    updateCity(city: string): void {
      this.address.addressCity = city;
      this.$emit('update-city', city);
    },

    updateZipcode(zipcode: string): void {
      this.address.addressZipcode = zipcode;
      this.$emit('update-zipcode', zipcode);
    },

    updateState(state: string): void {
      this.address.addressState = state;
      this.$emit('update-state', state);
    },

    updateCountry(country: string): void {
      this.address.addressCountry = country;
      this.$emit('update-country', country);
    }
  }
});
